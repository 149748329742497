import * as _ from "lodash";
import { PhoneNumberUtil } from "google-libphonenumber";
import { Engine } from "json-rules-engine";

export function discardQueryParamsFromImageUrl(imageUrl) {
  if (imageUrl != null) {
    imageUrl = _.replace(imageUrl, '[\\[\\]"]', "");
    if (_.includes(imageUrl, "?"))
      imageUrl = imageUrl.substring(0, imageUrl.indexOf("?"));
  }
  return imageUrl;
}

export function getContactData(contactInfo) {
  let dialCode, mobileNumber, email;

  if (!contactInfo) return {};

  if (!_.isEmpty(contactInfo.emails)) {
    email = contactInfo.emails[0].email;
  }

  if (!_.isEmpty(contactInfo.phones)) {
    // FIXME: find better way to convert int to string
    dialCode = contactInfo.phones[0].dial_code + "";
    mobileNumber = contactInfo.phones[0].mobile_number;
    if (!_.includes(dialCode, "+")) {
      // FIXME: find better way for concatenation
      dialCode = "+" + dialCode;
    }
  }
  return { dialCode, mobileNumber, email };
}

export function isValidEmail(value) {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

export function isValidPhone(value) {
  let isValidPhone = false;
  let phoneUtil = PhoneNumberUtil.getInstance();
  try {
    let number = phoneUtil.parse(`+${value}`);
    isValidPhone = phoneUtil.isValidNumber(number);
  } catch (error) {
    console.log("Error", error);
  }

  return isValidPhone;
}

export function getDialCodeAndNumber(value) {
  let phoneUtil = PhoneNumberUtil.getInstance();
  let cca2 = "IN",
    mobileNumber = "",
    dialCode = 91;
  try {
    let number = phoneUtil.parse(value);
    cca2 = phoneUtil.getRegionCodeForCountryCode(number.values_[1]);
    dialCode = phoneUtil.getCountryCodeForRegion(cca2);
    mobileNumber = number.values_[2];
  } catch (error) {
    console.log("Error", error);
  }

  return [cca2, mobileNumber, dialCode];
}

export function separateNumberAndCode(value) {
  let phoneUtil = PhoneNumberUtil.getInstance();
  let cca2 = "IN",
    mobileNumber = "",
    dialCode = 91;
  try {
    let number = phoneUtil.parse(value);
    cca2 = phoneUtil.getRegionCodeForCountryCode(number.values_[1]);
    dialCode = phoneUtil.getCountryCodeForRegion(cca2);
    mobileNumber = number.values_[2];
  } catch (error) {
    console.log("Error", error);
  }

  return [cca2, mobileNumber, dialCode];
}

export function getPassId(passes) {
  if (!_.isEmpty(passes)) {
    let splits = passes.slice(-1)[0].url.split("/");
    console.log("splits", splits);

    return splits[splits.length - 2];
  }

  return undefined;
}

export function getUploadUrl(imageName, baseUrl, isWebCheckin) {
  let baseDomain = baseUrl || "";
  if (isWebCheckin) {
    return `${baseDomain}/media/${imageName}`;
  }
  return `${baseDomain}/media/visitors/${imageName}`;
}

export function getDomainUrl(url) {
  try {
    let baseDomain = window.location.host;
    if (!_.isEmpty(url)) {
      url = new URL(url);
      url.host = baseDomain;

      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        // dev code
        return url.href
          ?.replace?.("https://", "http://")
          .replace("live.veris.in", "localhost:3000");
      } else {
        // production code
        return url.href?.replace?.("http://", "https://");
      }
    }
    return url;
  } catch (error) {
    console.log("error pdf: ", error);
  }
}

export function sanitize(string) {
  if (typeof string !== "string" || string.startsWith("http")) {
    return string;
  }
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;",
  };
  const reg = /[&<>"'/]/gi;
  return string?.replace(reg, (match) => map[match])?.trim() || string;
}

export function getFileUrl(imageName) {
  let baseDomain = window.location.origin;
  return `${baseDomain}/media/visitors/${imageName}`;
}

export function replaceAll(str, map) {
  for (let key in map) {
    str = str.replaceAll(`{{${key}}}`, map[key]).replaceAll(key, map[key]);
  }
  return str;
}

export function isMergedWorkflowDataValid(originalScreens, workflow_data) {
  let executionFailed = false,
    temp = [];
  if (!_.isEmpty(originalScreens)) {
    temp = JSON.parse(JSON.stringify(originalScreens));
    executionFailed = temp.some((screens) => {
      return screens.screen.form_fields.some((field) => {
        field["value"] = getValueFromWorkflowDataById(workflow_data, field.id);
        return field.required && _.isEmpty(field.value);
      });
    });
  }
  //if executionFailed is true, return false else the screens data
  return executionFailed ? !executionFailed : temp;
}

export function getValueFromWorkflowDataById(workflow_data, id) {
  let value = "";
  if (id && workflow_data && workflow_data.length > 0) {
    workflow_data.forEach((field) => {
      if (field.id === id) {
        value = field.value || "";
      }
    });
  }
  return value;
}

export function mobileBrowserCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

// export const evaluateFieldRules = async (field, activityLog) => {
//   console.log("DEBUG field: ", field);
//   console.log("DEBUG activityLog: ", activityLog);

//   let response = {
//     is_visible: true,
//   };

//   try {
//     const invited = !!activityLog?.user_pass?.[0]?.id;
//     const meta = field?.meta;

//     if (!meta.rules) {
//       return response;
//     }

//     // const rule = {
//     //     conditions: {
//     //         all: [
//     //             {
//     //                 fact: "is_invited",
//     //                 operator: "equal",
//     //                 value: true,
//     //             },
//     //         ],
//     //     },
//     //     event: {
//     //         type: "is_visible",
//     //     },
//     // };

//     const engine = new Engine();
//     const rule = meta.rules;
//     const facts = {
//       is_invited: invited,
//     };
//     engine.addRule(rule);

//     // hide the field in case of not invited
//     // const results = await engine.run(facts);
//     // const isvisible = results.events.find(
//     //   (event) => event.type === "is_visible"
//     // );

//     // if (!isvisible?.type === "is_visible") {
//     //   response.is_visible = false;
//     // }

//     engine.run(facts).then((events) => {
//       console.log("DEBUG events: ", events);
//       events?.events?.map((event) => {
//         if (event.type === "is_visible") {
//           response.is_visible = true;
//         } else {
//           response.is_visible = false;
//         }
//       });
//     });

//     return response;

//     // return response;
//   } catch (err) {
//     console.error(err);
//     return response;
//   }
// };

export const evaluateFieldRules = async (field, activityLog) => {
  const activityType = activityLog?.activityType ? activityLog?.activityType : "web-checkin";

  let response = {
    is_visible: true,
  };

  try {
    // const invited = !!activityLog?.user_pass?.[0]?.id;
    // const invited = true;
    const meta = field?.meta;

    if (!meta.rules) {
      return response;
    }

    const engine = new Engine();
    const rule = meta.rules;
    const facts = {
      activity_type: activityType,
      // is_invited: isWebCheckin ? true : false,
      // is_vCard: isWebCheckin ? false : true,
      // activity_type:activityLog.activity_type
    };

    engine.addRule(rule);

    // hide the field in case of not invited
    const results = await engine.run(facts);
    const isvisible = results.events.find(
      (event) => event.type === "is_visible"
    );
    if (!isvisible) {
      response.is_visible = false;
    }
    return response;
  } catch (err) {
    console.error(err);
    return response;
  }
};
