import * as types from "../actions/actionTypes";

export default function urlParamReducer(state = {}, action) {
    switch (action.type) {
        case types.UPDATE_PARAMS:
            console.log(action.data);
            return {...state, ...action.data};
        case types.CLEAR_PARAMS:
            return {};
        case types.FLUSH_SESSION_FROM_REDUX:
            return {};
        default:
            return state;
    }
}
