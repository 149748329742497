import {AsyncStorage, Platform} from 'react-native'
import {browserHistory} from 'react-router'
import store from "../storage/store";
import {ClearSessionFromRedux} from "../../actions/logoutActions";
import * as variables from "../../utilities/variables";
import axios from "axios/index";
import _ from "lodash";
import cogoToast from "cogo-toast";
import {getLanguage, setLanguage} from "../i18n/utils";

class AxiosSingleton {
    static instance = undefined;
    static base_url = undefined;
    static token = undefined;

    static createInstance(authToken, baseUrl) {
        if (!authToken) {
            return undefined
        }
        let axiosInstance = axios.create({
            baseURL: baseUrl,
            timeout: 30000,
            httpAgent: 'ReactNative',
            httpsAgent: 'ReactNative',
            headers: {
                common: {
                    'Authorization': `keepsake ${authToken}`,
                    'Accept': `application/json`,
                    'platform': Platform.OS === "android" ? "android" : "ios",
                    'Content-Type': `application/json`,
                },
            },
        });
        axiosInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.code === "ECONNABORTED") {
                    return {error: true, case: 'timeout'}
                }
                if (error.response && error.response.status === 401) {
                    AxiosSingleton.instance = undefined;
                    logoutUser()
                }
                return error.response ? error.response : error;
            });
        return axiosInstance;
    };

    static getInstance(token, url = '/') {
        if (!AxiosSingleton.instance || (!_.isEmpty(url) && AxiosSingleton.base_url !== url) || (!_.isEmpty(url) && AxiosSingleton.token !== token)) {
            token = token || store.getState().session[variables.TOKEN];
            AxiosSingleton.instance = AxiosSingleton.createInstance(token, url);
            AxiosSingleton.base_url = url;
            AxiosSingleton.token = token;
        }
        return AxiosSingleton.instance;
    };

    static getBaseUrl() {
        return AxiosSingleton.base_url
    }

    static getToken() {
        return AxiosSingleton.token  || store.getState().session[variables.TOKEN];
    }


    static resetInstance() {
        AxiosSingleton.instance = undefined;
        AxiosSingleton.base_url = undefined;
        AxiosSingleton.token = undefined;
    }
}

export default AxiosSingleton;

export function logoutUser(forceLogout) {
    try {
        console.log(`trying to log out user`);
        let asyncRemoveValues = AsyncStorage.multiRemove(variables.ALL_KEYS);
        asyncRemoveValues.then((value, errors) => {
            store.dispatch(ClearSessionFromRedux());
            AxiosSingleton.resetInstance();
            localStorage.removeItem('language');
            let alertMsg = "Your session has expired, please scan QR from terminal to proceed.";
            if (forceLogout)
                alertMsg = "You've been logged out. Please scan QR from terminal again to proceed"
            cogoToast.error(alertMsg);
            let locale = getLanguage();
            setLanguage(locale);
            browserHistory.replace('/scanner');
        });
    } catch (error) {
        console.log(error);
    }
}



