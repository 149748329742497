import { combineReducers } from "redux";
import SessionReducer from "./sessionReducer";
import LogReducer from "./logReducer";
import InviteLogReducer from "./inviteLogReducer";
import stylingReducer from "./stylingReducer";
import urlParamReducer from "./urlParamReducer";
import detection from "./detectionReducer";

//this is the list of final reducers
export default combineReducers({
  session: SessionReducer,
  activityLog: LogReducer,
  inviteLog: InviteLogReducer,
  style: stylingReducer,
  urlParams: urlParamReducer,
  detection,
});
