import * as types from "../actions/actionTypes";

export default function stylingReducer(state = {}, action) {
    switch (action.type) {
        case types.UPDATE_DIMENSIONS:
            console.log(action.data);
            return {...state, ...action.data};
        case types.FLUSH_SESSION_FROM_REDUX:
            return {};
        default:
            return state;
    }
}
