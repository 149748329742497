import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import WebCheckInApp from "./webCheckIn/app";
import "antd/dist/antd.css";

// sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://cbe536112e014d62b58b65a86c482d1d@sentryy.veris.in/4",
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === "console") {
      return null;
    }
    return breadcrumb;
  },
  environment: "production",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/sentryy.veris.in\.io\/api/,
  ],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

var renderedApp = <App />;
if (window.location.href.indexOf("invite") > -1) {
  renderedApp = <WebCheckInApp />;
}
// var renderedApp = <WebCheckInApp />;
ReactDOM.render(renderedApp, document.getElementById("root"));
