import translator from "i18next";
import {initReactI18next} from "react-i18next";
import {translations} from "./staticTranslations";

translator
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            "en": {
                "translation": translations['en']
            },
            "es": {
                "translation": translations['es']
            },
            "zh-CN": {
                "translation": translations['zh-CN']
            },
            "hi": {
                "translation": translations['hi']
            },
            "fr": {
                "translation": translations['fr']
            },
            "de": {
                "translation": translations['de']
            },
            "ro": {
                "translation": translations['ro']
            },
            "hu": {
                "translation": translations['hu']
            },
            "pt": {
                "translation": translations['pt']
            },
            "ja": {
                "translation": translations['ja']
            }

        },
        lng: localStorage.getItem("language"),
        keySeparator: '::',
        fallbackLng: 'en',
        saveMissing: true,
        // keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });


export default translator
