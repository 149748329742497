export const translations = {
    "en": {
      "End time should be greater than start time": "End time should be greater than start time",
      "Start time should be greater than current time. Please select start time again": "Start time should be greater than current time. Please select start time again",
      "Start time should be greater than current time": "Start time should be greater than current time",
      "Something went wrong!": "Something went wrong!",
      "Check In": "Check In",
      "Check-in": "Check-in",
      "Check Out": "Check Out",
      "Check-out": "Check-out",
      "Activity Details": "Activity Details",
      "Choose a language": "Choose a language",
      "Scan QR Code below": "Scan QR Code below",
      "Please go to settings and give camera permission.": "Please go to settings and give camera permission.",
      "Welcome to {{orgName}}": "Welcome to {{orgName}}",
      "Please enter your Email ID to continue": "Please enter your Email ID to continue",
      "Enter Phone Number or E-mail": "Enter Phone Number or E-mail",
      "This link will be valid for 10 minutes": "This link will be valid for 10 minutes",
      "Begin Registration": "Begin Registration",
      "By continuing further, you agree to": "By continuing further, you agree to",
      "Terms of Use": "Terms of use",
      "Privacy Policy": "Privacy policy",
      "and": "and",
      "Veris": "Veris",
      "Enter OTP received on {{userContactValue}} to start": "Enter OTP received on {{userContactValue}} to start",
      "to start": "to start",
      "Verify OTP": "Verify OTP",
      "We are having trouble connecting to your phone's camera, this may be due to an unsupported browser or you might have blocked the web app from accessing the camera.": "We are having trouble connecting to your phone's camera, this may be due to an unsupported browser or you might have blocked the web app from accessing the camera.",
      "Please either give camera permission or use any third party QR Scanning app or use a supportable browser like Safari in iOS or Chrome in android.": "Please either give camera permission or use any third party QR Scanning app or use a supportable browser like Safari in iOS or Chrome in android.",
      "You can resend OTP in": "You can resend OTP in",
      "seconds": "seconds",
      "Please enter a valid {{data}}": "Please enter a valid value",
      "Please enter a valid OTP": "Please enter a valid OTP",
      "Invalid OTP": "Invalid OTP",
      "Resend OTP": "Resend OTP",
      "Request Meeting": "Request Meeting",
      "What do you want to do?": "What do you want to do?",
      "Are you sure you want to logout?": "Are you sure you want to logout?",
      "This will delete all your user data": "This will delete all your user data",
      "Cancel": "Cancel",
      "Yes, logout": "Yes, logout",
      "Your First Name": "Your First Name",
      "Your Last Name": "Your Last Name",
      "Meeting With?": "Meeting With?",
      "Enter minimum 3 characters to search host": "Enter minimum 3 characters to search host",
      "Meeting Start Time": "Meeting Start Time",
      "Meeting End Time": "Meeting End Time",
      "Meeting Agenda": "Meeting Agenda",
      "Optional": "Optional",
      "Select host from dropdown only.": "Select host from dropdown only.",
      "Do you wish to carry your": "Do you wish to carry your",
      "mobile ?": "mobile ?",
      "Please check-out first to check-in again": "Please check-out first to check-in again",
      "Request placed Successfully!": "Request placed Successfully!",
      "Select things you wish to carry": "Select things you wish to carry",
      "Select options": "Select options",
      "Start time should always be less than end time": "Start time should always be less than end time",
      "Last Name cannot be blank": "Last Name cannot be blank",
      "First Name cannot be blank": "First Name cannot be blank",
      "Hi": "Hi",
      "Host": "Host",
      "Check-in Time": "Check-in Time",
      "Instructions": "Instructions",
      "Stay safe and maintain social distancing": "Stay safe and maintain social distancing",
      "You are back online!": "You are back online!",
      "You are offline, please check you internet connection!": "You are offline, please check you internet connection!",
      "Your session has expired, please scan QR from terminal to proceed.": "Your session has expired, please scan QR from terminal to proceed.",
      "You've been logged out. Please scan QR from terminal again to proceed": "You've been logged out. Please scan QR from terminal again to proceed",
      "Error in sending OTP": "Error in sending OTP",
      "This link has expired. Please scan the code again from terminal to proceed.": "This link has expired. Please scan the code again from terminal to proceed.",
      "User Profile not found, proceed by entering your phone number": "User Profile not found, proceed by entering your phone number",
      "TrueCaller not installed": "TrueCaller not installed",
      "Requested user not belong to any Invite Group": "Requested user not belong to any Invite Group",
      "In application camera is not supported or we are unable to connect to it, please proceed by uploading your image": "In application camera is not supported or we are unable to connect to it, please proceed by uploading your image",
      "We ask for permission to access your camera to click your picture, however the access is blocked or you are using an unsupported browser. Please proceed by uploading your image": "We ask for permission to access your camera to click your picture, however the access is blocked or you are using an unsupported browser. Please proceed by uploading your image",
      "QR not detected. Try placing the QR in the center of camera": "QR not detected. Try placing the QR in the center of camera",
      "Couldn't load camera. Try clicking a picture using the button below": "Couldn't load camera. Try clicking a picture using the button below",
      "Error occured": "Error occured",
      "Download Badge": "Download Badge",
      "Next": "Next",
      "Retake": "Retake",
      "Use Image": "Use Image",
      "Click Picture": "Click Picture",
      "Upload Picture": "Upload Picture",
      "Processing Request": "Processing Request",
      "You can read the NDA here. To proceed, please sign in the area below": "You can read the NDA here. To proceed, please sign in the area below",
      "Sign Below": "Sign Below",
      "Clear": "Clear",
      "We have your photo. You can use this photo or click another one": "We have your photo. You can use this photo or click another one",
      "We'll use your camera to capture image of your Govt. ID": "We'll use your camera to capture image of your Govt. ID",
      "We have image of your Govt. ID. You can use this photo or click another one": "We have image of your Govt. ID. You can use this photo or click another one",
      "We'll use your camera to capture your photo for this visit": "We'll use your camera to capture your photo for this visit",
      "Please sign above to continue": "Please sign above to continue",
      "Please agree to above.": "Please agree to above.",
      "You can only select {{count}} option": "You can only select {{count}} option",
      "You can only select {{count}} option_plural": "You can only select {{count}} options",
      "You can only select up to {{count}} option": "You can only select up to {{count}} option",
      "You can only select up to {{count}} option_plural": "You can only select up to {{count}} options",
      "You can only select {{v1}} to {{v2}} options": "You can only select {{v1}} to {{v2}} options",
      "Please fill the details": "Please fill the details",
      "No choices available": "No choices available",
      "This field is required": "This field is required",
      "Select atleast 1 value": "Select atleast 1 value",
      "Error": "Error",
      "Valid From": "Valid From",
      "Valid Till": "Valid Till",
      "Invite QR": "Invite QR",
      "Additional Info": "Additional Info",
      "info-checkIn-super-fast-venue": "You can fill the information here for super-fast check-in at the Venue.",
      "dont-fill-form-again": "Please do not fill this form again if you have filled it once.",
      "Domain": "Domain",
      "Change domain?": "Change domain?",
      "Email Id": "Email",
      "Mobile Number": "Phone",
      "Email ID": "Email",
      "Email": "Email",
      "Phone": "Phone",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Become Member": "Become Member",
      "Company Name": "Company Name",
      "ID Type": "ID Type",
      "ID Number": "ID Number",
      "Who do you wish to meet": "Who do you wish to meet",
      "Purpose of Visit": "Purpose of Visit",
      "Arrival Instructions": "Arrival Instructions",
      "Destination": "Destination",
      "Is Anyone Going to Escort you": "Is Anyone Going to Escort you",
      "Escort Name": "Escort Name",
      "Escort ID": "Escort ID",
      "Personal Item 1": "Personal Item 1",
      "Personal Item 2": "Personal Item 2",
      "Personal Item 3": "Personal Item 3",
      "Personal Item 4": "Personal Item 4",
      "Confirmation": "Confirmation",
      "Address": "Address",
      "Please fill following information": "Please fill following information",
      "cannot be empty": "cannot be empty",
      "This is not a valid value": "This is not a valid value",
      "This": "This",
      "is not valid": "is not valid",
      "Enter mobile number": "Enter mobile number",
      "Enter the name of employee you wish to meet": "Enter the name of employee you wish to meet",
      "Enter your Company Name": "Enter your Company Name",
      "Enter your Email ID": "Enter your Email ID",
      "Enter your First Name": "Enter your First Name",
      "Enter your Last Name": "Enter your Last Name",
      "Enter your mobile number or email id": "Enter your mobile number or email id",
      "I": "I",
      "This field cannot be empty": "This field cannot be empty",
      "Please select an option": "Please select an option",
      "Click here": "Click here",
      "Unable to find someone": "Unable to find someone",
      "Unable to find member": "Unable to find member",
      "Confirm submission": "Confirm submission",
      "Contact Details": "Contact Details",
      "Please Enter your Email ID": "Please enter your Email ID",
      "Submit Information": "Submit Information",
      "Click here to read rules and regulations": "Click here to read rules and regulations",
      "Select your purpose": "Select your purpose",
      "accept the above terms and condition": "accept the above terms and condition",
      "Organization": "Organization",
      "You've been invited to": "You've been invited to",
      "Your information has been successfully submitted.": "Your information has been successfully submitted.",
      "You can close this window now.": "You can close this window now.",
      "Please fill the following Information Fields": "Please fill the following Information Fields",
      "Invite Code":"Invite Code",
      "Begin WebCheckin": "Begin Web Check in",
      'Skip':'Skip',
      "Refuse": "Refuse",
      "Experience a super fast check in by completing the Web Check in process.":"Experience a super fast check in by completing the Web Check in process.",
      "QR Code can be used for Parking/Locker": "QR Code can be used for Parking/Locker",
      "Navigate": "Navigate",
      "Add to calendar": "Add to calendar",
      "Tap to close zoom": "Tap to close zoom"
    },
    "es":{
      "End time should be greater than start time": "La hora de finalización debe ser posterior a la de inicio.",
      "Start time should be greater than current time. Please select start time again": "La hora de inicio debe ser posterior a la hora actual. Seleccione de nuevo la hora de inicio",
      "Start time should be greater than current time": "La hora de inicio debe ser posterior a la hora actual",
      "Something went wrong!": "¡Algo salió mal!",
      "Check In": "Registrarse",
      "Check-in": "Registro de entrada",
      "Check Out": "Salir",
      "Check-out": "Registro de salida",
      "Activity Details": "Detalles de actividad",
      "Choose a language": "Elija un idioma",
      "Scan QR Code below": "Escanee el siguiente código QR",
      "Please go to settings and give camera permission.": "Vaya a configuración y conceda permiso a la cámara.",
      "Welcome to {{orgName}}": "Bienvenido a {{orgName}}",
      "Please enter your contact details to continue": "Ingrese sus datos de contacto para continuar",
      "Enter Phone Number or E-mail": "Ingrese número de teléfono o correo electrónico",
      "This link will be valid for 10 minutes": "Este enlace tendrá una validez de 10 minutos",
      "Begin Registration": "Comenzar el registro",
      "By continuing further, you agree to": "Al continuar, usted acepta los",
      "Terms of Use": "Términos de uso",
      "Privacy Policy": "Política de privacidad",
      "and": "y",
      "Veris": "Veris",
      "Enter OTP received on {{userContactValue}} to start": "Ingrese la contraseña de un solo uso recibida en {{userContactValue}} para comenzar",
      "to start": "para comenzar",
      "Verify OTP": "Verificar contraseña de un solo uso",
      "We are having trouble connecting to your phone's camera, this may be due to an unsupported browser or you might have blocked the web app from accessing the camera.": "Tenemos problemas para conectarnos a la cámara de su teléfono, es posible que su navegador no sea compatible o que haya bloqueado el acceso de la aplicación web a la cámara.",
      "Please either give camera permission or use any third party QR Scanning app or use a supportable browser like Safari in iOS or Chrome in android.": "Conceda el permiso a la cámara o utilice cualquier aplicación de escaneo QR de terceros o un navegador compatible como Safari en iOS o Chrome en Android.",
      "You can resend OTP in": "Puede reenviar la contraseña de un solo uso en",
      "seconds": "segundos",
      "Please enter a valid {{data}}": "Ingrese un valor válido",
      "Please enter a valid OTP": "Ingrese una contraseña de un solo uso válida",
      "Invalid OTP": "Contraseña de un solo uso no válida",
      "Resend OTP": "Reenviar contraseña de un solo uso",
      "Request Meeting": "Solicitar reunión",
      "What do you want to do?": "¿Qué quiere hacer?",
      "Are you sure you want to logout?": "¿Está seguro de que quiere cerrar la sesión?",
      "This will delete all your user data": "Al cerrar, se eliminarán todos sus datos de usuario",
      "Cancel": "Cancelar",
      "Yes, logout": "Sí, cerrar sesión",
      "Your First Name": "Su nombre",
      "Your Last Name": "Su apellido",
      "Meeting With?": "¿Se reunirá con?",
      "Enter minimum 3 characters to search host": "Ingrese un mínimo de 3 caracteres para buscar al anfitrión",
      "Meeting Start Time": "Hora de inicio de la reunión",
      "Meeting End Time": "Hora de finalización de la reunión",
      "Meeting Agenda": "Agenda de la reunión",
      "Optional": "Opcional",
      "Select host from dropdown only.": "Seleccione el anfitrión únicamente en el menú desplegable.",
      "Do you wish to carry your": "¿Desea llevar su",
      "mobile ?": "teléfono celular?",
      "Please check-out first to check-in again": "Primero registre su salida para volver a registrar su entrada",
      "Request placed Successfully!": "¡La solicitud se ha realizado con éxito!",
      "Select things you wish to carry": "Seleccione las cosas que desea llevar",
      "Select options": "Seleccione las opciones",
      "Start time should always be less than end time": "La hora de inicio debe ser siempre anterior a la hora de finalización",
      "Last Name cannot be blank": "Apellido no puede estar en blanco",
      "First Name cannot be blank": "Nombre no puede estar en blanco",
      "Hi": "Hola",
      "Host": "Anfitrión",
      "Check-in Time": "Hora de entrada",
      "Instructions": "Instrucciones",
      "Stay safe and maintain social distancing": "Mantenga la seguridad y el distanciamiento social",
      "You are back online!": "¡Ha vuelto a conectarse!",
      "You are offline, please check you internet connection!": "¡Está desconectado, verifique su conexión a internet!",
      "Your session has expired, please scan QR from terminal to proceed.": "Su sesión ha vencido, escanee el código QR del terminal para continuar.",
      "You've been logged out. Please scan QR from terminal again to proceed": "Se ha cerrado su sesión. Escanee de nuevo el código QR del terminal para continuar",
      "Error in sending OTP": "Error en el envío de la contraseña de un solo uso",
      "This link has expired. Please scan the code again from terminal to proceed.": "Este enlace ha vencido. Escanee el código de nuevo desde el terminal para continuar.",
      "User Profile not found, proceed by entering your phone number": "No se encontró el perfil de usuario, continúe ingresando su número de teléfono",
      "TrueCaller not installed": "TrueCaller no está instalado",
      "Requested user not belong to any Invite Group": "El usuario solicitado no pertenece a ningún grupo de invitados",
      "In application camera is not supported or we are unable to connect to it, please proceed by uploading your image": "La cámara de la aplicación no es compatible o no podemos conectarnos a ella, proceda subiendo su imagen",
      "We ask for permission to access your camera to click your picture, however the access is blocked or you are using an unsupported browser. Please proceed by uploading your image": "Le pedimos permiso para acceder a su cámara para hacer clic en su foto. Sin embargo, el acceso está bloqueado o está utilizando un navegador incompatible. Proceda subiendo su imagen",
      "QR not detected. Try placing the QR in the center of camera": "No se detectó el código QR. Trate de colocar el QR en el centro de la cámara",
      "Couldn't load camera. Try clicking a picture using the button below": "No se ha podido cargar la cámara. Trate de hacer clic en una imagen utilizando el botón de abajo",
      "Error occured": "Se ha producido un error",
      "Download Badge": "Descargue la credencial",
      "Next": "Siguiente",
      "Retake": "Repetir",
      "Use Image": "Usar imagen",
      "Click Picture": "Hacer clic en la imagen",
      "Upload Picture": "Subir imagen",
      "Processing Request": "Procesando solicitud",
      "You can read the NDA here. To proceed, please sign in the area below": "Puede leer el Acuerdo de confidencialidad aquí. Para continuar, firme en el área de abajo",
      "Sign Below": "Firme abajo",
      "Clear": "Borrar",
      "We have your photo. You can use this photo or click another one": "Tenemos su foto. Puede utilizar esta foto o hacer clic en otra",
      "We'll use your camera to capture image of your Govt. ID": "Usaremos su cámara para capturar la imagen de su identificación gubernamental",
      "We have image of your Govt. ID. You can use this photo or click another one": "Tenemos la imagen de su identificación gubernamental. Puede utilizar esta foto o hacer clic en otra",
      "We'll use your camera to capture your photo for this visit": "Usaremos su cámara para capturar su foto para esta visita",
      "Please sign above to continue": "Firme arriba para continuar",
      "Please agree to above.": "Acepte lo anterior.",
      "You can only select {{count}} option": "Solo puede seleccionar {{count}} opción",
      "You can only select {{count}} option_plural": "Solo puede seleccionar {{count}} opciones",
      "You can only select up to {{count}} option": "Solo puede seleccionar hasta {{count}} opción",
      "You can only select up to {{count}} option_plural": "Solo puede seleccionar hasta {{count}} opciones",
      "You can only select {{v1}} to {{v2}} options": "Solo puede seleccionar de {{v1}} a {{v2}} opciones",
      "Please fill the details": "Complete los detalles",
      "No choices available": "No hay opciones disponibles",
      "This field is required": "Este campo es obligatorio",
      "Select atleast 1 value": "Seleccione al menos 1 valor",
      "Error": "Error",
      "Valid From": "Válido desde",
      "Valid Till": "Válido hasta",
      "Invite QR": "Invitación QR",
      "Additional Info": "Información adicional",
      "info-checkIn-super-fast-venue": "Para registrar su entrada rápidamente al lugar, puede completar la información aquí",
      "dont-fill-form-again": "No complete este formulario de nuevo si ya lo hizo una vez.",
      "Domain": "Dominio",
      "Change domain?": "¿Cambio de dominio?",
      "Email Id": "Correo electrónico",
      "Mobile Number": "Teléfono",
      "Email ID": "Correo electrónico",
      "Email": "Correo electrónico",
      "Phone": "Teléfono",
      "First Name": "Nombre",
      "Last Name": "Apellido",
      "Become Member": "Convertirse en miembro",
      "Company Name": "Nombre de la empresa",
      "ID Type": "Tipo de identificación",
      "ID Number": "Número de identificación",
      "Who do you wish to meet": "¿Con quién quiere reunirse?",
      "Purpose of Visit": "Motivo de la visita",
      "Arrival Instructions": "Instrucciones de llegada",
      "Destination": "Destino",
      "Is Anyone Going to Escort you": "¿Alguien le va a acompañar?",
      "Escort Name": "Nombre del acompañante",
      "Escort ID": "Identificación del acompañante",
      "Personal Item 1": "Artículo personal 1",
      "Personal Item 2": "Artículo personal 2",
      "Personal Item 3": "Artículo personal 3",
      "Personal Item 4": "Artículo personal 4",
      "Confirmation": "Confirmación",
      "Address": "Dirección",
      "Please fill following information": "Complete la siguiente información",
      "cannot be empty": "no puede estar vacío",
      "This is not a valid value": "Este valor no es válido",
      "This": "Este",
      "is not valid": "no es válido",
      "Enter mobile number": "Ingrese número de celular",
      "Enter the name of employee you wish to meet": "Ingrese nombre del empleado con el que desea reunirse",
      "Enter your Company Name": "Ingrese nombre de su empresa",
      "Enter your Email ID": "Ingrese su dirección de correo electrónico",
      "Enter your First Name": "Ingrese su nombre",
      "Enter your Last Name": "Ingrese su apellido",
      "Enter your mobile number or email id": "Ingrese su número de celular o dirección de correo electrónico",
      "I": "I",
      "This field cannot be empty": "Este campo no puede estar vacío",
      "Please select an option": "Seleccione una opción",
      "Click here": "Haga clic aquí",
      "Unable to find someone": "No se pudo encontrar a alguien",
      "Unable to find member": "No se pudo encontrar a un miembro",
      "Confirm submission": "Confirmar envío",
      "Contact Details": "Datos de contacto",
      "Please Enter your Email ID": "Ingrese su dirección de correo electrónico",
      "Submit Information": "Enviar información",
      "Click here to read rules and regulations": "Haga clic aquí para leer las reglas y regulaciones",
      "Select your purpose": "Seleccione su motivo",
      "accept the above terms and condition": "aceptar los términos y condiciones anteriores",
      "Organization": "Organización",
      "You've been invited to": "Usted ha sido invitado a",
      "Your information has been successfully submitted.": "Su información ha sido enviada con éxito.",
      "You can close this window now.": "Ya puede cerrar esta ventana.",
      "Please fill the following Information Fields": "Complete los siguientes campos de información",
      "Invite Code":"Codigo de invitacion",
      "Begin WebCheckin": "Comenzar el registro web",
      'Skip':'Saltar',
      "Refuse": "Negar",
      "Experience a super fast check in by completing the Web Check in process.": "Experimente un registro súper rápido completando el proceso de verificación web.",
      "QR Code can be used for Parking/Locker": "El código QR se puede usar para el estacionamiento/casillero",
      "Navigate": "Navegar",
      "Add to calendar": "Añadir al calendario",
      "Tap to close zoom": "Toque para cerrar el zoom"
      },
      "zh-CN":{
        "End time should be greater than start time": "结束时间应大于开始时间",
        "Start time should be greater than current time. Please select start time again": "开始时间应大于当前时间。请重新选择开始时间",
        "Start time should be greater than current time": "开始时间应大于当前时间",
        "Something went wrong!": "出错了！",
        "Check In": "签入",
        "Check-in": "签入",
        "Check Out": "签出",
        "Check-out": "签出",
        "Activity Details": "活动详情",
        "Choose a language": "选择语言",
        "Scan QR Code below": "扫描下面的二维码",
        "Please go to settings and give camera permission.": "请进入设置并授予相机许可。",
        "Welcome to {{orgName}}": "欢迎使用{{orgName}}",
        "Please enter your contact details to continue": "请输入您的联系方式以继续",
        "Enter Phone Number or E-mail": "输入电话号码或电子邮件",
        "This link will be valid for 10 minutes": "此链接有效期为10分钟",
        "Begin Registration": "开始注册",
        "By continuing further, you agree to": "继续进行，即为您同意",
        "Terms of Use": "使用条款",
        "Privacy Policy": "隐私政策",
        "and": "和",
        "Veris": "Veris",
        "Enter OTP received on {{userContactValue}} to start": "输入在{{userContactValue}}收到的一次性密码以开始",
        "to start": "开始",
        "Verify OTP": "验证一次性密码",
        "We are having trouble connecting to your phone's camera, this may be due to an unsupported browser or you might have blocked the web app from accessing the camera.": "我们连接您手机的相机时遇到麻烦，这可能是由于浏览器不受支持，或者您可能已阻止网络应用访问相机。",
        "Please either give camera permission or use any third party QR Scanning app or use a supportable browser like Safari in iOS or Chrome in android.": "请授予相机许可或使用任何第三方二维码扫描应用，或使用受支持的浏览器（如iOS中的Safari或Android中的Chrome）。",
        "You can resend OTP in": "您可在…后重新发送一次性密码",
        "seconds": "秒",
        "Please enter a valid {{data}}": "请输入一个有效的值",
        "Please enter a valid OTP": "请输入有效的一次性密码",
        "Invalid OTP": "无效的一次性密码",
        "Resend OTP": "重新发送一次性密码",
        "Request Meeting": "请求会面",
        "What do you want to do?": "您想做什么？",
        "Are you sure you want to logout?": "您确定要退出吗？",
        "This will delete all your user data": "这将删除您的所有用户数据",
        "Cancel": "取消",
        "Yes, logout": "是的，退出",
        "Your First Name": "您的名字",
        "Your Last Name": "您的姓氏",
        "Meeting With?": "与…会面？",
        "Enter minimum 3 characters to search host": "输入至少3个字符以搜索主人",
        "Meeting Start Time": "会面开始时间",
        "Meeting End Time": "会面结束时间",
        "Meeting Agenda": "会面日程",
        "Optional": "可选",
        "Select host from dropdown only.": "仅从下拉列表中选择主人。",
        "Do you wish to carry your": "您希望携带您的",
        "mobile ?": "手机吗？",
        "Please check-out first to check-in again": "请先签出，然后再签入",
        "Request placed Successfully!": "请求已成功放置！",
        "Select things you wish to carry": "选择您希望携带的东西",
        "Select options": "选择选项",
        "Start time should always be less than end time": "开始时间必须早于结束时间",
        "Last Name cannot be blank": "姓氏不能为空",
        "First Name cannot be blank": "名字不能为空",
        "Hi": "您好",
        "Host": "主人",
        "Check-in Time": "签入时间",
        "Instructions": "指示",
        "Stay safe and maintain social distancing": "保证安全并保持社交距离",
        "You are back online!": "您已重新上线！",
        "You are offline, please check you internet connection!": "您处于离线状态，请检查您的互联网连接！",
        "Your session has expired, please scan QR from terminal to proceed.": "您的会话已过期，请从终端扫描二维码以继续。",
        "You've been logged out. Please scan QR from terminal again to proceed": "您已退出。请重新从终端扫描二维码以继续",
        "Error in sending OTP": "发送一次性密码时出错",
        "This link has expired. Please scan the code again from terminal to proceed.": "此链接已过期。请重新从终端扫描代码以继续。",
        "User Profile not found, proceed by entering your phone number": "未找到用户个人资料，请输入您的电话号码",
        "TrueCaller not installed": "未安装TrueCaller",
        "Requested user not belong to any Invite Group": "被请求的用户不属于任何邀约组",
        "In application camera is not supported or we are unable to connect to it, please proceed by uploading your image": "应用内相机不受支持，或者我们无法与其连接，请上传您的图像以继续",
        "We ask for permission to access your camera to click your picture, however the access is blocked or you are using an unsupported browser. Please proceed by uploading your image": "我们请求访问您的相机的许可，以点击您的图片，但是访问被阻止或您使用的浏览器不受支持。请上传您的图片以继续",
        "QR not detected. Try placing the QR in the center of camera": "未检测到二维码。尝试将二维码置于相机的中央",
        "Couldn't load camera. Try clicking a picture using the button below": "无法加载相机。尝试使用下面的按钮点击图片",
        "Error occured": "出现错误",
        "Download Badge": "下载徽章",
        "Next": "下一步",
        "Retake": "重拍",
        "Use Image": "使用图像",
        "Click Picture": "点击图片",
        "Upload Picture": "上传图片",
        "Processing Request": "正在处理请求",
        "You can read the NDA here. To proceed, please sign in the area below": "您可在此处阅读保密协议。若要继续，请在以下区域登录",
        "Sign Below": "在下面签名",
        "Clear": "清除",
        "We have your photo. You can use this photo or click another one": "我们有您的照片。您可以使用这张照片，也可以点击另一张",
        "We'll use your camera to capture image of your Govt. ID": "我们将使用您的相机捕捉您的身份证图像",
        "We have image of your Govt. ID. You can use this photo or click another one": "我们有您的身份证照片。您可以使用这张照片，也可以点击另一张",
        "We'll use your camera to capture your photo for this visit": "我们将使用您的相机为此次访问捕捉您的照片",
        "Please sign above to continue": "请在上方签名以继续",
        "Please agree to above.": "请同意以上内容。",
        "You can only select {{count}} option": "您只能选择{{count}}个选项",
        "You can only select {{count}} option_plural": "您只能选择{{count}}个选项",
        "You can only select up to {{count}} option": "您最多只能选择{{count}}个选项",
        "You can only select up to {{count}} option_plural": "您最多只能选择{{count}}个选项",
        "You can only select {{v1}} to {{v2}} options": "您只能选择{{v1}}至{{v2}}选项",
        "Please fill the details": "请填写详细信息",
        "No choices available": "没有选项可供选择",
        "This field is required": "此字段为必填",
        "Select atleast 1 value": "选择至少1个值",
        "Error": "错误",
        "Valid From": "有效期自",
        "Valid Till": "有效期至",
        "Invite QR": "邀约二维码",
        "Additional Info": "附加信息",
        "info-checkIn-super-fast-venue": "您可在此处填写信息，以便在场所超快速办理签入手续。",
        "dont-fill-form-again": "如果您曾填写过，请勿重新填写此表。",
        "Domain": "域名",
        "Change domain?": "变更域名？",
        "Email Id": "电子邮件",
        "Mobile Number": "电话",
        "Email ID": "电子邮件",
        "Email": "电子邮件",
        "Phone": "电话",
        "First Name": "名",
        "Last Name": "姓",
        "Become Member": "成为会员",
        "Company Name": "公司名称",
        "ID Type": "ID类型",
        "ID Number": "ID号码",
        "Who do you wish to meet": "您想与谁会面？",
        "Purpose of Visit": "访问目的",
        "Arrival Instructions": "到达指示",
        "Destination": "目的地",
        "Is Anyone Going to Escort you": "是否有人陪同",
        "Escort Name": "陪同人姓名",
        "Escort ID": "陪同人ID",
        "Personal Item 1": "个人物品1",
        "Personal Item 2": "个人物品2",
        "Personal Item 3": "个人物品3",
        "Personal Item 4": "个人物品4",
        "Confirmation": "确认",
        "Address": "地址",
        "Please fill following information": "请填写以下信息",
        "cannot be empty": "不能为空",
        "This is not a valid value": "这不是有效值",
        "This": "这",
        "is not valid": "是无效的",
        "Enter mobile number": "输入手机号码",
        "Enter the name of employee you wish to meet": "输入您要会面的员工姓名",
        "Enter your Company Name": "输入您的公司名称",
        "Enter your Email ID": "输入您的电子邮件地址",
        "Enter your First Name": "输入您的名字",
        "Enter your Last Name": "输入您的姓氏",
        "Enter your mobile number or email id": "输入您的手机号码或电子邮件地址",
        "I": "我",
        "This field cannot be empty": "该字段不能为空",
        "Please select an option": "请选择一个选项",
        "Click here": "点击此处",
        "Unable to find someone": "无法找到某人",
        "Unable to find member": "无法找到会员",
        "Confirm submission": "确认提交",
        "Contact Details": "联系方式",
        "Please Enter your Email ID": "请输入您的电子邮件地址",
        "Submit Information": "提交信息",
        "Click here to read rules and regulations": "点击此处阅读规则和规定",
        "Select your purpose": "选择您的目的",
        "accept the above terms and condition": "接受上述条款与条件",
        "Organization": "组织",
        "You've been invited to": "您已受邀到",
        "Your information has been successfully submitted.": "您的信息已成功提交。",
        "You can close this window now.": "您现在可以关闭此窗口。",
        "Please fill the following Information Fields": "请填写以下信息字段",
        "Invite Code" : "邀请代码",
        "Begin WebCheckin": "开始网上签到",
        'Skip':'跳过',
        "Refuse": "拒绝",
        "Experience a super fast check in by completing the Web Check in process.":"通过完成Web检查进程，体验超快速的检查。",
        "QR Code can be used for Parking/Locker": "QR码可用于停车/储物柜",
        "Navigate": "导航",
        "Add to calendar": "添加到日历",
        "Tap to close zoom": "点击关闭变焦"
      },
      "ja":{
        "End time should be greater than start time": "終了時刻は開始時刻より後でなければなりません",
        "Start time should be greater than current time. Please select start time again": "開始時刻は現在時刻より後でなければなりません。もう一度開始時刻を選択してください。",
        "Start time should be greater than current time": "開始時刻は現在時刻より後でなければなりません",
        "Something went wrong!": "問題が発生しました！",
        "Check In": "チェックイン",
        "Check-in": "チェックイン",
        "Check Out": "チェックアウト",
        "Check-out": "チェックアウト",
        "Activity Details": "アクティビティの詳細",
        "Choose a language": "言語を選択してください",
        "Scan QR Code below": "下のQRコードをスキャンしてください",
        "Please go to settings and give camera permission.": "設定に移動して、カメラへのアクセスを許可してください。",
        "Welcome to {{orgName}}": "{{orgName}}へようこそ",
        "Please enter your contact details to continue": "続行するには連絡先の詳細を入力してください",
        "Enter Phone Number or E-mail": "電話番号またはメールアドレスを入力してください",
        "This link will be valid for 10 minutes": "このリンクは10分間有効となります",
        "Begin Registration": "登録開始",
        "By continuing further, you agree to": "続行した場合、以下に同意したものとみなされます：",
        "Terms of Use": "利用規約",
        "Privacy Policy": "プライバシーポリシー",
        "and": "および",
        "Veris": "Veris",
        "Enter OTP received on {{userContactValue}} to start": "開始するには、{{userContactValue}}で受信したワンタイムパスワードを入力してください",
        "to start": "開始するには",
        "Verify OTP": "ワンタイムパスワードの検証",
        "We are having trouble connecting to your phone's camera, this may be due to an unsupported browser or you might have blocked the web app from accessing the camera.": "携帯電話のカメラへの接続で問題が発生しています。非対応のブラウザーを使用しているか、ウェブアプリがカメラにアクセスするのを拒否した可能性があります。",
        "Please either give camera permission or use any third party QR Scanning app or use a supportable browser like Safari in iOS or Chrome in android.": "カメラへのアクセスを許可するか、サードパーティのQRコードスキャンアプリまたは対応しているブラウザー（iOSの場合はSafari、Androidの場合はChromeなど）をご使用ください。",
        "You can resend OTP in": "ワンタイムパスワードの再送信が可能になるまで後",
        "seconds": "秒",
        "Please enter a valid {{data}}": "有効な値を入力してください",
        "Please enter a valid OTP": "有効なワンタイムパスワードを入力してください",
        "Invalid OTP": "ワンタイムパスワードが無効です",
        "Resend OTP": "ワンタイムパスワードを再送信",
        "Request Meeting": "面会をリクエスト",
        "What do you want to do?": "希望する操作をお選びください",
        "Are you sure you want to logout?": "本当にログアウトしますか？",
        "This will delete all your user data": "ユーザーデータはすべて削除されます",
        "Cancel": "キャンセル",
        "Yes, logout": "はい、ログアウトします",
        "Your First Name": "名",
        "Your Last Name": "姓",
        "Meeting With?": "面会の相手は？",
        "Enter minimum 3 characters to search host": "ホストを検索するには3文字以上入力してください",
        "Meeting Start Time": "ミーティングの開始時刻",
        "Meeting End Time": "ミーティングの終了時刻",
        "Meeting Agenda": "ミーティングのアジェンダ",
        "Optional": "任意",
        "Select host from dropdown only.": "ホストはドロップダウンからのみ選択できます。",
        "Do you wish to carry your": "次の機器の持ち込みを希望しますか：",
        "mobile ?": "携帯電話",
        "Please check-out first to check-in again": "再度チェックインするには、一度チェックアウトしてください",
        "Request placed Successfully!": "リクエストが完了しました！",
        "Select things you wish to carry": "持ち込みたいものを選択してください",
        "Select options": "オプションを選択してください",
        "Start time should always be less than end time": "開始時刻は必ず終了時刻より前でなければなりません",
        "Last Name cannot be blank": "姓を空欄にすることはできません",
        "First Name cannot be blank": "名を空欄にすることはできません",
        "Hi": "こんにちは",
        "Host": "ホスト",
        "Check-in Time": "チェックイン時刻",
        "Instructions": "ご案内",
        "Stay safe and maintain social distancing": "安全に配慮し、ソーシャルディスタンスを保ちましょう",
        "You are back online!": "再びオンラインになりました！",
        "You are offline, please check you internet connection!": "現在オフラインです。インターネット接続を確認してください！",
        "Your session has expired, please scan QR from terminal to proceed.": "セッションの有効期限が切れました。続行するには受付端末のQRコードをスキャンしてください。",
        "You've been logged out. Please scan QR from terminal again to proceed": "ログアウトしました。続行するには、もう一度受付端末のQRコードをスキャンしてください。",
        "Error in sending OTP": "ワンタイムパスワードの送信エラー",
        "This link has expired. Please scan the code again from terminal to proceed.": "このリンクは有効期限が切れています。続行するには、もう一度受付端末のコードをスキャンしてください。",
        "User Profile not found, proceed by entering your phone number": "ユーザープロファイルが見つかりません。電話番号を入力して続行してください。",
        "TrueCaller not installed": "TrueCallerがインストールされていません",
        "Requested user not belong to any Invite Group": "リクエストされたユーザーはどの招待グループにも属していません",
        "In application camera is not supported or we are unable to connect to it, please proceed by uploading your image": "アプリケーションでカメラがサポートされていないか、接続できません。画像をアップロードして続行してください。",
        "We ask for permission to access your camera to click your picture, however the access is blocked or you are using an unsupported browser. Please proceed by uploading your image": "写真をクリックするにはカメラへのアクセス許可が必要ですが、アクセスが拒否されているか、非対応のブラウザーを使用しています。画像をアップロードして続行してください。",
        "QR not detected. Try placing the QR in the center of camera": "QRコードが検出されませんでした。QRコードがカメラの中央に位置するようにしてください。",
        "Couldn't load camera. Try clicking a picture using the button below": "カメラを読み込めませんでした。下のボタンを使って写真をクリックしてみてください。",
        "Error occured": "エラーが発生しました",
        "Download Badge": "バッジをダウンロード",
        "Next": "次へ",
        "Retake": "再撮影",
        "Use Image": "画像を使う",
        "Click Picture": "写真をクリック",
        "Upload Picture": "写真をアップロード",
        "Processing Request": "リクエストを処理しています",
        "You can read the NDA here. To proceed, please sign in the area below": "こちらで秘密保持契約を読むことができます。続行するには、下のエリアに署名してください。",
        "Sign Below": "この下に署名してください",
        "Clear": "消去",
        "We have your photo. You can use this photo or click another one": "既に写真が登録されています。この写真を使用するか、別の写真をクリックできます。",
        "We'll use your camera to capture image of your Govt. ID": "公的身分証明書の写真を撮るために携帯のカメラを使用します",
        "We have image of your Govt. ID. You can use this photo or click another one": "既に公的身分証明書の画像が登録されています。この写真を使用するか、別の写真をクリックできます。",
        "We'll use your camera to capture your photo for this visit": "今回の訪問の写真を撮るため、携帯のカメラを使用します",
        "Please sign above to continue": "続行するには上に署名してください",
        "Please agree to above.": "上記に同意してください。",
        "You can only select {{count}} option": "選択できるオプションは{{count}}個のみです",
        "You can only select {{count}} option_plural": "選択できるオプションは{{count}}個のみです",
        "You can only select up to {{count}} option": "選択できるオプションは{{count}}個までです",
        "You can only select up to {{count}} option_plural": "選択できるオプションは{{count}}個までです",
        "You can only select {{v1}} to {{v2}} options": "選択できるオプションは{{v1}}～{{v2}}個のみです",
        "Please fill the details": "詳細を記入してください",
        "No choices available": "選択肢がありません",
        "This field is required": "この項目は必須項目です",
        "Select atleast 1 value": "少なくとも値を1つ選択してください",
        "Error": "エラー",
        "Valid From": "有効期間開始",
        "Valid Till": "有効期間終了",
        "Invite QR": "招待用QRコード",
        "Additional Info": "追加情報",
        "info-checkIn-super-fast-venue": "現地での超スピードチェックインのため、こちらに情報を記入できます。",
        "dont-fill-form-again": "このフォームに一度記入した事がある場合は、再記入しないでください。",
        "Domain": "ドメイン",
        "Change domain?": "ドメインを変更しますか？",
        "Email Id": "メール",
        "Mobile Number": "電話",
        "Email ID": "メール",
        "Email": "メール",
        "Phone": "電話",
        "First Name": "名",
        "Last Name": "姓",
        "Become Member": "メンバーになる",
        "Company Name": "会社名",
        "ID Type": "身分証明書の種類",
        "ID Number": "身分証明書番号",
        "Who do you wish to meet": "面会を希望する相手は誰ですか",
        "Purpose of Visit": "訪問目的",
        "Arrival Instructions": "到着時の案内文",
        "Destination": "訪問先",
        "Is Anyone Going to Escort you": "訪問先",
        "Escort Name": "案内係の名前",
        "Escort ID": "案内係のID",
        "Personal Item 1": "個人の持ち物1",
        "Personal Item 2": "個人の持ち物2",
        "Personal Item 3": "個人の持ち物3",
        "Personal Item 4": "個人の持ち物4",
        "Confirmation": "確認",
        "Address": "住所",
        "Please fill following information": "次の情報を記入してください",
        "cannot be empty": "空欄にはできません",
        "This is not a valid value": "有効な値ではありません",
        "This": "これは",
        "is not valid": "有効ではありません",
        "Enter mobile number": "携帯番号を入力してください",
        "Enter the name of employee you wish to meet": "面会したい従業員の名前を入力してください",
        "Enter your Company Name": "御社の社名を入力してください",
        "Enter your Email ID": "メールアドレスを入力してください",
        "Enter your First Name": "名を入力してください",
        "Enter your Last Name": "姓を入力してください",
        "Enter your mobile number or email id": "携帯番号またはメールアドレスを入力してください",
        "I": "私は",
        "This field cannot be empty": "この項目を空欄にすることはできません",
        "Please select an option": "オプションを1つ選択してください",
        "Click here": "こちらをクリックしてください",
        "Unable to find someone": "該当者が見つかりません",
        "Unable to find member": "メンバーが見つかりません",
        "Confirm submission": "送信を確認",
        "Contact Details": "連絡先の詳細",
        "Please Enter your Email ID": "メールアドレスを入力してください",
        "Submit Information": "情報を送信",
        "Click here to read rules and regulations": "各種規則を読むにはこちらをクリックしてください",
        "Select your purpose": "目的を選択してください",
        "accept the above terms and condition": "上記の諸条件に同意してください",
        "Organization": "組織",
        "You've been invited to": "次の組織に招待されました：",
        "Your information has been successfully submitted.": "情報が送信されました。",
        "You can close this window now.": "この画面を閉じていただいて結構です。",
        "Please fill the following Information Fields": "次の情報を記入してください",
        "Invite Code" : "招待コード",
        "Begin WebCheckin": "Webチェックインを開始します",
        'Skip':'スキップ',
        "Refuse": "ごみ",
        "Experience a super fast check in by completing the Web Check in process.":"Webチェックインのプロセスを完了して、超高速チェックインを体験してください。",
        "QR Code can be used for Parking/Locker": "QRコードは、駐車/ロッカーに使用できます",
        "Navigate": "ナビゲートします",
        "Add to calendar": "カレンダーに追加します",
        "Tap to close zoom": "タップしてズームを閉じます"
      },
      "ro":{
        "End time should be greater than start time": "Ora de încheiere trebuie să fie după ora de începere",
        "Start time should be greater than current time. Please select start time again": "Ora de începere trebuie să fie după ora curentă. Selectați din nou ora de începere",
        "Start time should be greater than current time": "Ora de începere trebuie să fie după ora curentă",
        "Something went wrong!": "Ceva n-a mers bine!",
        "Check In": "Intrare",
        "Check-in": "Intrare",
        "Check Out": "Ieșire",
        "Check-out": "Ieșire",
        "Activity Details": "Detalii despre activitate",
        "Choose a language": "Alegeți o limbă",
        "Scan QR Code below": "Scanați codul QR de mai jos",
        "Please go to settings and give camera permission.": "Accesați setările și permiteți accesul la cameră.",
        "Welcome to {{orgName}}": "Bun venit la {{orgName}}",
        "Please enter your contact details to continue": "Vă rugăm să introduceți datele de contact pentru a continua",
        "Enter Phone Number or E-mail": "Introduceți numărul de telefon sau adresa de e-mail",
        "This link will be valid for 10 minutes": "Acest link va fi valabil timp de 10 minute",
        "Begin Registration": "Începeți înregistrarea",
        "By continuing further, you agree to": "Mergând mai departe, sunteți de acord cu",
        "Terms of Use": "Condițiile de utilizare",
        "Privacy Policy": "Politica de confidențialitate",
        "and": "și",
        "Veris": "Veris",
        "Enter OTP received on {{userContactValue}} to start": "Introduceți OTP primit pe {{userContactValue}} pentru a începe",
        "to start": "a începe",
        "Verify OTP": "Verificare OTP",
        "We are having trouble connecting to your phone's camera, this may be due to an unsupported browser or you might have blocked the web app from accessing the camera.": "Întâmpinăm probleme cu conectarea la camera telefonului dvs.; poate fi din cauza unui browser neacceptat sau este posibil să fi blocat accesul aplicației web la cameră.",
        "Please either give camera permission or use any third party QR Scanning app or use a supportable browser like Safari in iOS or Chrome in android.": "Vă rugăm să permiteți accesul la cameră sau să folosiți orice aplicație terță de scanare QR sau să utilizați un browser compatibil, cum ar fi Safari în iOS sau Chrome în Android.",
        "You can resend OTP in": "Puteți retrimite OTP în",
        "seconds": "secunde",
        "Please enter a valid {{data}}": "Vă rugăm să introduceți o valoare validă",
        "Please enter a valid OTP": "Vă rugăm să introduceți un OTP valid",
        "Invalid OTP": "OTP nevalid",
        "Resend OTP": "Retrimitere OTP",
        "Request Meeting": "Solicitați întâlnire",
        "What do you want to do?": "Ce vreți să faceți?",
        "Are you sure you want to logout?": "Sigur doriți să vă deconectați?",
        "This will delete all your user data": "Se vor șterge toate datele de utilizator",
        "Cancel": "Anulare",
        "Yes, logout": "Da, deconectează-mă",
        "Your First Name": "Prenumele dvs.",
        "Your Last Name": "Numele dvs. de familie",
        "Meeting With?": "Întâlnire cu?",
        "Enter minimum 3 characters to search host": "Introduceți minimum 3 caractere pentru a căuta gazda",
        "Meeting Start Time": "Ora de începere a întâlnirii",
        "Meeting End Time": "Ora de încheiere a întâlnirii",
        "Meeting Agenda": "Agenda întâlnirii",
        "Optional": "Opțional",
        "Select host from dropdown only.": "Selectați gazda numai din meniul derulant.",
        "Do you wish to carry your": "Doriți să vă luați",
        "mobile ?": "mobilul?",
        "Please check-out first to check-in again": "Vă rugăm să marcați mai întâi ieșirea, apoi să vă înregistrați din nou",
        "Request placed Successfully!": "Cerere plasată cu succes!",
        "Select things you wish to carry": "Selectați lucrurile pe care doriți să le luați cu dvs.",
        "Select options": "Selectați opțiunile",
        "Start time should always be less than end time": "Ora de începere trebuie să fie întotdeauna înainte de ora de încheiere",
        "Last Name cannot be blank": "Numele de familie trebuie completat",
        "First Name cannot be blank": "Prenumele trebuie completat",
        "Hi": "Salut",
        "Host": "Gazdă",
        "Check-in Time": "Ora de intrare",
        "Instructions": "Instrucțiuni",
        "Stay safe and maintain social distancing": "Rămâneți în siguranță și mențineți distanța socială",
        "You are back online!": "Sunteți din nou online!",
        "You are offline, please check you internet connection!": "Sunteți offline, vă rugăm să verificați conexiunea la internet!",
        "Your session has expired, please scan QR from terminal to proceed.": "Sesiunea dvs. a expirat, vă rugăm să scanați codul QR de la terminal pentru a continua.",
        "You've been logged out. Please scan QR from terminal again to proceed": "Ați fost deconectat. Scanați din nou codul QR de la terminal pentru a continua",
        "Error in sending OTP": "Eroare la trimiterea OTP",
        "This link has expired. Please scan the code again from terminal to proceed.": "Acest link a expirat. Vă rugăm să scanați din nou codul de la terminal pentru a continua.",
        "User Profile not found, proceed by entering your phone number": "Profilul utilizatorului nu a fost găsit, continuați introducând numărul dvs. de telefon",
        "TrueCaller not installed": "TrueCaller nu este instalat",
        "Requested user not belong to any Invite Group": "Utilizatorul solicitat nu aparține niciunui grup de invitați",
        "In application camera is not supported or we are unable to connect to it, please proceed by uploading your image": "În aplicație camera nu este acceptată sau nu ne putem conecta la aceasta; continuați încărcând imaginea",
        "We ask for permission to access your camera to click your picture, however the access is blocked or you are using an unsupported browser. Please proceed by uploading your image": "Vă cerem permisiunea de a vă accesa camera pentru a apăsa pe imaginea dvs., dar accesul este blocat sau utilizați un browser incompatibil. Vă rugăm să continuați încărcând imaginea",
        "QR not detected. Try placing the QR in the center of camera": "Codul QR nu a fost detectat. Încercați să plasați codul QR în centrul camerei",
        "Couldn't load camera. Try clicking a picture using the button below": "Nu s-a putut încărca camera. Încercați să apăsați pe o imagine folosind butonul de mai jos",
        "Error occured": "A apărut o eroare",
        "Download Badge": "Descărcare ecuson",
        "Next": "Următorul",
        "Retake": "Reluare",
        "Use Image": "Folosiți imaginea",
        "Click Picture": "Apăsați pe imagine",
        "Upload Picture": "Încărcare imagine",
        "Processing Request": "Se procesează cererea",
        "You can read the NDA here. To proceed, please sign in the area below": "Puteți citi documentul NDA aici. Pentru a continua, vă rugăm să vă conectați în zona de mai jos",
        "Sign Below": "Semnați mai jos",
        "Clear": "Ștergere",
        "We have your photo. You can use this photo or click another one": "Avem fotografia dvs. Puteți utiliza această fotografie sau puteți alege alta",
        "We'll use your camera to capture image of your Govt. ID": "Vom folosi camera dvs. pentru a capta imaginea actului dvs. de identitate",
        "We have image of your Govt. ID. You can use this photo or click another one": "Avem imaginea actului dvs. de identitate. Puteți utiliza această fotografie sau puteți alege alta",
        "We'll use your camera to capture your photo for this visit": "Vom folosi camera pentru a vă capta fotografia pentru această vizită",
        "Please sign above to continue": "Vă rugăm să semnați mai sus pentru a continua",
        "Please agree to above.": "Vă rugăm să fiți de acord cu cele de mai sus.",
        "You can only select {{count}} option": "Puteți selecta doar {{count}} opțiune",
        "You can only select {{count}} option_plural": "Puteți selecta doar {{count}} opțiuni",
        "You can only select up to {{count}} option": "Puteți selecta cel mult {{count}} opțiune",
        "You can only select up to {{count}} option_plural": "Puteți selecta cel mult {{count}} opțiuni",
        "You can only select {{v1}} to {{v2}} options": "Puteți selecta doar între {{v1}} și {{v2}} opțiuni",
        "Please fill the details": "Vă rugăm să completați detaliile",
        "No choices available": "Nu există opțiuni disponibile",
        "This field is required": "Acest câmp este obligatoriu",
        "Select atleast 1 value": "Selectați cel puțin 1 valoare",
        "Error": "Eroare",
        "Valid From": "Valabil de la",
        "Valid Till": "Valabil până la",
        "Invite QR": "Cod QR invitație",
        "Additional Info": "Informații suplimentare",
        "info-checkIn-super-fast-venue": "Puteți completa informațiile aici pentru o Intrare foarte rapidă în locație.",
        "dont-fill-form-again": "Vă rugăm să nu completați din nou acest formular dacă l-ați completat o dată.",
        "Domain": "Domeniu",
        "Change domain?": "Schimbați domeniul?",
        "Email Id": "E-mail",
        "Mobile Number": "Telefon",
        "Email ID": "E-mail",
        "Email": "E-mail",
        "Phone": "Telefon",
        "First Name": "Prenume",
        "Last Name": "Nume de familie",
        "Become Member": "Deveniți membru",
        "Company Name": "Denumire companie",
        "ID Type": "Tip ID",
        "ID Number": "Număr ID",
        "Who do you wish to meet": "Cu cine doriți să vă întâlniți",
        "Purpose of Visit": "Scopul vizitei",
        "Arrival Instructions": "Instrucțiuni la sosire",
        "Destination": "Destinație",
        "Is Anyone Going to Escort you": "Veți fi însoțit de cineva",
        "Escort Name": "Numele însoțitorului",
        "Escort ID": "ID însoțitor",
        "Personal Item 1": "Articol personal 1",
        "Personal Item 2": "Articol personal 2",
        "Personal Item 3": "Articol personal 3",
        "Personal Item 4": "Articol personal 4",
        "Confirmation": "Confirmare",
        "Address": "Adresa",
        "Please fill following information": "Vă rugăm să completați următoarele informații",
        "cannot be empty": "nu poate rămâne necompletat",
        "This is not a valid value": "Aceasta nu este o valoare validă",
        "This": "Aceasta",
        "is not valid": "nu e validă",
        "Enter mobile number": "Introduceți numărul de telefon mobil",
        "Enter the name of employee you wish to meet": "Introduceți numele angajatului pe care doriți să-l întâlniți",
        "Enter your Company Name": "Introduceți denumirea companiei",
        "Enter your Email ID": "Introduceți ID-ul de e-mail",
        "Enter your First Name": "Introduceți prenumele",
        "Enter your Last Name": "Introduceți numele de familie",
        "Enter your mobile number or email id": "Introduceți numărul de telefon mobil sau ID-ul de e-mail",
        "I": "Eu",
        "This field cannot be empty": "Acest câmp nu poate rămâne necompletat",
        "Please select an option": "Selectați o opțiune",
        "Click here": "Apăsați aici",
        "Unable to find someone": "Nu s-a găsit nicio persoană",
        "Unable to find member": "Membrul nu a fost găsit",
        "Confirm submission": "Confirmare trimitere",
        "Contact Details": "Date de contact",
        "Please Enter your Email ID": "Vă rugăm să introduceți ID-ul de e-mail",
        "Submit Information": "Trimitere informații",
        "Click here to read rules and regulations": "Apăsați aici pentru a citi regulile și reglementările",
        "Select your purpose": "Selectați scopul",
        "accept the above terms and condition": "acceptați termenii și condițiile de mai sus",
        "Organization": "Organizație",
        "You've been invited to": "Ați fost invitat la",
        "Your information has been successfully submitted.": "Informațiile dvs. au fost trimise cu succes.",
        "You can close this window now.": "Puteți închide această fereastră acum.",
        "Please fill the following Information Fields": "Vă rugăm să completați următoarele câmpuri de informații",
        "Invite Code" : "Cod de invitație",
        "Begin WebCheckin": "Începeți verificarea web",
        'Skip':'Ocolire',
        "Refuse": "Refuza",
        "Experience a super fast check in by completing the Web Check in process.":"Experimentați o verificare super rapidă completând procesul de verificare web.",
        "QR Code can be used for Parking/Locker": "Codul QR poate fi utilizat pentru parcare/vestiar",
        "Navigate": "Navigați",
        "Add to calendar": "Adăugați în calendar",
        "Tap to close zoom": "Atingeți pentru a închide Zoom"
      },
      "pt":{
        "End time should be greater than start time": "A hora de término deve ser posterior à hora de início",
        "Start time should be greater than current time. Please select start time again": "A hora de início deve ser posterior à hora atual. Selecione a hora de início novamente",
        "Start time should be greater than current time": "A hora de início deve ser posterior à hora atual",
        "Something went wrong!": "Algo deu errado!",
        "Check In": "Registrar entrada",
        "Check-in": "Registrar entrada",
        "Check Out": "Registrar saída",
        "Check-out": "Registrar saída",
        "Activity Details": "Detalhes da atividade",
        "Choose a language": "Selecione um idioma",
        "Scan QR Code below": "Escanear código QR abaixo",
        "Please go to settings and give camera permission.": "Vá em configurações e autorize a câmera.",
        "Welcome to {{orgName}}": "Boas-vindas a {{orgName}}",
        "Please enter your contact details to continue": "Insira seus dados de contato para continuar",
        "Enter Phone Number or E-mail": "Insira o telefone ou e-mail",
        "This link will be valid for 10 minutes": "Este link será válido por 10 minutos",
        "Begin Registration": "Iniciar cadastro",
        "By continuing further, you agree to": "Ao continuar, você concorda com",
        "Terms of Use": "Termos de Uso",
        "Privacy Policy": "Política de privacidade",
        "and": "e",
        "Veris": "Veris",
        "Enter OTP received on {{userContactValue}} to start": "Digite OTP recebido em {{userContactValue}} para iniciar",
        "to start": "para iniciar",
        "Verify OTP": "Confirmar OTP",
        "We are having trouble connecting to your phone's camera, this may be due to an unsupported browser or you might have blocked the web app from accessing the camera.": "Estamos tendo problemas para conectar a câmera do seu celular. Isso pode ser devido a um navegador não compatível ou você pode ter bloqueado o aplicativo da web de acessar a câmera.",
        "Please either give camera permission or use any third party QR Scanning app or use a supportable browser like Safari in iOS or Chrome in android.": "Autorize a câmera ou use algum aplicativo para Escanear QR de terceiros, ou use um navegador compatível, como Safari no iOS ou Chrome no Android.",
        "You can resend OTP in": "OTP pode ser reenviado em",
        "seconds": "segundos",
        "Please enter a valid {{data}}": "Insira um valor válido",
        "Please enter a valid OTP": "Insira um OTP válido",
        "Invalid OTP": "OTP inválido",
        "Resend OTP": "Reenviar OTP",
        "Request Meeting": "Solicitar reunião",
        "What do you want to do?": "O que você quer fazer?",
        "Are you sure you want to logout?": "Tem certeza que quer sair?",
        "This will delete all your user data": "Isso excluirá todos os seus dados de usuário",
        "Cancel": "Cancelar",
        "Yes, logout": "Sim, sair",
        "Your First Name": "Seu nome",
        "Your Last Name": "Seu sobrenome",
        "Meeting With?": "Reunião com?",
        "Enter minimum 3 characters to search host": "Insira no mínimo 3 caracteres para pesquisar o anfitrião",
        "Meeting Start Time": "Hora de início da reunião",
        "Meeting End Time": "Horário de término da reunião",
        "Meeting Agenda": "Agenda da reunião",
        "Optional": "Opcional",
        "Select host from dropdown only.": "Selecione o anfitrião apenas na lista suspensa.",
        "Do you wish to carry your": "Deseja levar o seu",
        "mobile ?": "celular ?",
        "Please check-out first to check-in again": "Registrar saída antes de novo registro de entrada",
        "Request placed Successfully!": "Pedido feito com sucesso!",
        "Select things you wish to carry": "Selecione as coisas que deseja transportar",
        "Select options": "Selecionar opções",
        "Start time should always be less than end time": "A hora de início deve ser sempre anterior à hora de término",
        "Last Name cannot be blank": "Sobrenome não pode ficar em branco",
        "First Name cannot be blank": "Nome não pode ficar em branco",
        "Hi": "Oi",
        "Host": "Anfitrião",
        "Check-in Time": "Hora de registro de entrada",
        "Instructions": "Instruções",
        "Stay safe and maintain social distancing": "Fique seguro e mantenha o distanciamento social",
        "You are back online!": "Você está on-line novamente!",
        "You are offline, please check you internet connection!": "Você está off-line, verifique sua conexão de Internet!",
        "Your session has expired, please scan QR from terminal to proceed.": "Sua sessão expirou. Escaneie o QR do terminal para continuar.",
        "You've been logged out. Please scan QR from terminal again to proceed": "Você foi desconectado. Escaneie o QR do terminal novamente para continuar",
        "Error in sending OTP": "Erro ao enviar OTP",
        "This link has expired. Please scan the code again from terminal to proceed.": "Este link expirou. Para continuar, escaneie novamente o código no terminal.",
        "User Profile not found, proceed by entering your phone number": "Perfil de usuário não encontrado, insira seu número de telefone para continuar",
        "TrueCaller not installed": "TrueCaller não instalado",
        "Requested user not belong to any Invite Group": "O usuário solicitado não pertence a nenhum grupo de convite",
        "In application camera is not supported or we are unable to connect to it, please proceed by uploading your image": "A câmera não é suportada no aplicativo ou não conseguimos conexão com ela. Faça upload da sua imagem para continuar",
        "We ask for permission to access your camera to click your picture, however the access is blocked or you are using an unsupported browser. Please proceed by uploading your image": "Pedimos permissão para acessar sua câmera para tirar sua foto, mas o acesso está bloqueado ou você está usando um navegador incompatível. Para continuar, faça upload de sua imagem",
        "QR not detected. Try placing the QR in the center of camera": "QR não detectado. Tente posicionar o QR no centro da câmera",
        "Couldn't load camera. Try clicking a picture using the button below": "Não foi possível carregar a câmera. Experimente tirarr uma foto usando o botão abaixo",
        "Error occured": "Ocorreu um erro",
        "Download Badge": "Baixar o crachá",
        "Next": "Próximo",
        "Retake": "Refazer",
        "Use Image": "Usar foto",
        "Click Picture": "Tirar foto",
        "Upload Picture": "Carregar foto",
        "Processing Request": "Pedido de processamento",
        "You can read the NDA here. To proceed, please sign in the area below": "Leia o NDA aqui. Para continuar, faça login na área abaixo",
        "Sign Below": "Cadastre-se abaixo",
        "Clear": "Limpar",
        "We have your photo. You can use this photo or click another one": "Temos a sua foto. Você pode usar esta foto ou tirar outra",
        "We'll use your camera to capture image of your Govt. ID": "Usaremos sua câmera para capturar a imagem da sua identificação emitida pelo governo",
        "We have image of your Govt. ID. You can use this photo or click another one": "Temos imagem da sua identificação emitida pelo governo. Você pode usar esta foto ou tirar outra",
        "We'll use your camera to capture your photo for this visit": "Usaremos sua câmera para capturar sua foto para esta visita",
        "Please sign above to continue": "Assine acima para continuar",
        "Please agree to above.": "Por favor, concorde com o acima.",
        "You can only select {{count}} option": "Só é possível selecionar {{count}} opção",
        "You can only select {{count}} option_plural": "Só é possível selecionar {{count}} opções",
        "You can only select up to {{count}} option": "Só é possível selecionar até {{count}} opção",
        "You can only select up to {{count}} option_plural": "Só é possível selecionar até {{count}} opções",
        "You can only select {{v1}} to {{v2}} options": "Só é possível selecionar de {{v1}} a {{v2}} opções",
        "Please fill the details": "Por favor, preencha os detalhes",
        "No choices available": "Nenhuma opção disponível",
        "This field is required": "Este campo é obrigatório",
        "Select atleast 1 value": "Selecione pelo menos 1 valor",
        "Error": "Erro",
        "Valid From": "Válido de",
        "Valid Till": "Válida até",
        "Invite QR": "QR Convite",
        "Additional Info": "Informações adicionais",
        "info-checkIn-super-fast-venue": "Preencha as informações aqui para registrar a entrada super-rápido no local.",
        "dont-fill-form-again": "Não preencha este formulário novamente se já o tiver preenchido uma vez.",
        "Domain": "Domínio",
        "Change domain?": "Mudar de domínio?",
        "Email Id": "E-mail",
        "Mobile Number": "Telefone",
        "Email ID": "E-mail",
        "Email": "E-mail",
        "Phone": "Telefone",
        "First Name": "Nome",
        "Last Name": "Sobrenome",
        "Become Member": "Torne-se membro",
        "Company Name": "Nome da empresa",
        "ID Type": "Tipo de Identificação",
        "ID Number": "Número de identificação",
        "Who do you wish to meet": "Quem você deseja encontrar",
        "Purpose of Visit": "Objetivo da visita",
        "Arrival Instructions": "Instruções de chegada",
        "Destination": "Destino",
        "Is Anyone Going to Escort you": "Você será acompanhado por alguém?",
        "Escort Name": "Nome do acompanhante",
        "Escort ID": "Identificação do acompanhante",
        "Personal Item 1": "Item pessoal 1",
        "Personal Item 2": "Item pessoal 2",
        "Personal Item 3": "Item pessoal 3",
        "Personal Item 4": "Item pessoal 4",
        "Confirmation": "Confirmação",
        "Address": "Endereço",
        "Please fill following information": "Preencha as seguintes informações",
        "cannot be empty": "não pode ficar vazio",
        "This is not a valid value": "Este não é um valor válido",
        "This": "Este",
        "is not valid": "não é válido",
        "Enter mobile number": "Insira o número do celular",
        "Enter the name of employee you wish to meet": "Digite o nome do funcionário que deseja encontrar",
        "Enter your Company Name": "Insira o nome da sua empresa",
        "Enter your Email ID": "Insira seu e-mail de identificação",
        "Enter your First Name": "Digite seu nome",
        "Enter your Last Name": "Digite seu sobrenome",
        "Enter your mobile number or email id": "Digite seu número de celular ou e-mail de identificação",
        "I": "Eu",
        "This field cannot be empty": "Este campo não pode ficar vazio",
        "Please select an option": "Selecione uma opção",
        "Click here": "Clique aqui",
        "Unable to find someone": "Não foi possível encontrar alguém",
        "Unable to find member": "Não foi possível encontrar um membro",
        "Confirm submission": "Confirmar envio",
        "Contact Details": "Detalhes do contato",
        "Please Enter your Email ID": "Digite seu e-mail de identificação",
        "Submit Information": "Enviar informações",
        "Click here to read rules and regulations": "Clique aqui para ler as regras e regulamentos",
        "Select your purpose": "Selecione seu objetivo",
        "accept the above terms and condition": "aceito os termos e condições acima",
        "Organization": "Organização",
        "You've been invited to": "Você foi convidado para",
        "Your information has been successfully submitted.": "Suas informações foram enviadas com sucesso.",
        "You can close this window now.": "Você já pode fechar esta janela.",
        "Please fill the following Information Fields": "Preencha os seguintes campos de informação",
        "Invite Code" : "Código de convite",
        "Begin WebCheckin": "Iniciar o check-in na web",
        'Skip':'Pular',
        "Refuse": "Recusar",
        "Experience a super fast check in by completing the Web Check in process.":"Experimente um check -in super rápido ao concluir o processo de check -in da web.",
        "QR Code can be used for Parking/Locker": "O código QR pode ser usado para estacionamento/armário",
        "Navigate": "Navegar",
        "Add to calendar": "Adicione ao calendário",
        "Tap to close zoom": "Toque para fechar o zoom"
      },
      "hu":{
        "End time should be greater than start time": "A befejezőidőpontnak a kezdőidőpontnál későbbinek kell lennie",
        "Start time should be greater than current time. Please select start time again": "A kezdőidőpontnak a jelenlegi időpontnál későbbinek kell lennie. Kérjük, válassz ki újra egy kezdőidőpontot",
        "Start time should be greater than current time": "A kezdőidőpontnak a jelenlegi időpontnál későbbinek kell lennie.",
        "Something went wrong!": "Valami hiba történt",
        "Check In": "Bejelentkezés",
        "Check-in": "Bejelentkezés",
        "Check Out": "Kijelentkezés",
        "Check-out": "Kijelentkezés",
        "Activity Details": "Tevékenység részletei",
        "Choose a language": "Nyelv kiválasztása",
        "Scan QR Code below": "QR-kód beolvasása alul",
        "Please go to settings and give camera permission.": "Kérjük, lépj a beállításokba és adj engedélyt a kamerának.",
        "Welcome to {{orgName}}": "Üdvözlünk a következőben: {{orgName}}",
        "Please enter your contact details to continue": "Kérjük, a folytatáshoz add meg az elérhetőséged",
        "Enter Phone Number or E-mail": "Telefonszám vagy e-mail megadása",
        "This link will be valid for 10 minutes": "Ez a link 10 percig lesz érvényes",
        "Begin Registration": "Regisztráció elkezdése",
        "By continuing further, you agree to": "A továbblépéssel elfogadod a következőt:",
        "Terms of Use": "Felhasználási feltételek",
        "Privacy Policy": "Adatvédelmi politika",
        "and": "és",
        "Veris": "Veris",
        "Enter OTP received on {{userContactValue}} to start": "a kezdéshez",
        "to start": "a kezdéshez",
        "Verify OTP": "Egyszer használatos jelszó (EHJ) megerősítése",
        "We are having trouble connecting to your phone's camera, this may be due to an unsupported browser or you might have blocked the web app from accessing the camera.": "Nem sikerül csatlakoznunk a telefonod kamerájához. Ezt okozhatja egy nem támogatott böngésző, vagy lehet, hogy a webes alkalmazásnak letiltottad a kamerádhoz való hozzáférést.",
        "Please either give camera permission or use any third party QR Scanning app or use a supportable browser like Safari in iOS or Chrome in android.": "Kérjük, adj engedélyt a kamerának, vagy használj egy harmadik féltől származó, QR-kód-beolvasó alkalmazást, esetleg használj egy támogatott szoftvert, mint a Safari az iOS-n vagy a Chrome az Androidon.",
        "You can resend OTP in": "Az EHJ-t ennyi időn belül újraküldheted:",
        "seconds": "másodperc",
        "Please enter a valid {{data}}": "Kérjük, érvényes értéket adj meg",
        "Please enter a valid OTP": "Kérjük, érvényes, EHJ-t adj meg",
        "Invalid OTP": "Érvénytelen EHJ",
        "Resend OTP": "EHJ újraküldése",
        "Request Meeting": "Találkozó kérése",
        "What do you want to do?": "Mit szeretnél tenni?",
        "Are you sure you want to logout?": "Biztos, hogy ki akarsz jelentkezni?",
        "This will delete all your user data": "Ez az összes felhasználói adatodat törölni fogja",
        "Cancel": "Mégse",
        "Yes, logout": "Igen, kijelentkezem",
        "Your First Name": "Utóneved",
        "Your Last Name": "Vezetékneved",
        "Meeting With?": "Kivel találkozol?",
        "Enter minimum 3 characters to search host": "A vendéglátó kereséséhez legalább 3 karaktert adj meg",
        "Meeting Start Time": "Találkozó kezdőidőpontja",
        "Meeting End Time": "Találkozó befejezőidőpontja",
        "Meeting Agenda": "Találkozó napirendje",
        "Optional": "Opcionális",
        "Select host from dropdown only.": "Vendéglátó kiválasztása csak a legördülő menüből.",
        "Do you wish to carry your": "Szeretnéd magaddal vinni a",
        "mobile ?": "mobiltelefonodat?",
        "Please check-out first to check-in again": "Kérjük, az újrabejelentkezéshez először jelentkezz ki",
        "Request placed Successfully!": "Kérés sikeresen elküldve!",
        "Select things you wish to carry": "Válaszd ki a tárgyakat, amiket szeretnél magaddal vinni",
        "Select options": "Opciók kiválasztása",
        "Start time should always be less than end time": "A kezdőidőpontnak a befejezés időpontjánál mindig korábbinak kell lennie",
        "Last Name cannot be blank": "A vezetéknév nem lehet üres",
        "First Name cannot be blank": "Az utónév nem lehet üres",
        "Hi": "Üdv",
        "Host": "Vendéglátó",
        "Check-in Time": "Bejelentkezés ideje",
        "Instructions": "Útmutatások",
        "Stay safe and maintain social distancing": "Maradj biztonságban és figyelj a közösségi távolságtartásra",
        "You are back online!": "Újra online vagy!",
        "You are offline, please check you internet connection!": "Offline vagy, kérjük, ellenőrizd az internetkapcsolatod!",
        "Your session has expired, please scan QR from terminal to proceed.": "Bejelentkezési időszakod lejárt. A folytatáshoz, kérjük, olvasd be a QR-t egy terminálon.",
        "You've been logged out. Please scan QR from terminal again to proceed": "Ki lettél léptetve. A folytatáshoz, kérjük, olvasd be a QR-t egy terminálon.",
        "Error in sending OTP": "Hiba az EHJ küldése során",
        "This link has expired. Please scan the code again from terminal to proceed.": "A link lejárt. A folytatáshoz, kérjük, a kódot olvasd be újra a terminálon.",
        "User Profile not found, proceed by entering your phone number": "Felhasználói profil nem található. A folytatáshoz, kérjük, add meg a telefonszámodat",
        "TrueCaller not installed": "TrueCaller nincs telepítve",
        "Requested user not belong to any Invite Group": "Az igényelt felhasználó nem tartozik egyik meghívási csoporthoz sem",
        "In application camera is not supported or we are unable to connect to it, please proceed by uploading your image": "Az alkalmazásban a kamera nincs támogatva, vagy mi nem tudunk kapcsolódni hozzá. A folytatáshoz, kérjük, töltsd fel a képedet",
        "We ask for permission to access your camera to click your picture, however the access is blocked or you are using an unsupported browser. Please proceed by uploading your image": "A képedre történő kattintás végett hozzáférést kérünk a kamerádhoz, azonban a hozzáférés vagy le van tiltva, vagy nem támogatott böngészőt használsz. A folytatáshoz, kérjük, töltsd fel a képedet",
        "QR not detected. Try placing the QR in the center of camera": "A QR nem észlelhető. Kérjük, próbáld meg a kamera középpontjába helyezni",
        "Couldn't load camera. Try clicking a picture using the button below": "Kamera betöltése nem sikerült. Kérjük, az alábbi gomb használatával próbálj rákattintani egy képre",
        "Error occured": "Hiba történt",
        "Download Badge": "Névkitűző letöltése",
        "Next": "Következő",
        "Retake": "Újrafelvétel",
        "Use Image": "Kép felhasználása",
        "Click Picture": "Kattintás a képre",
        "Upload Picture": "Kép feltöltése",
        "Processing Request": "Kérés feldolgozása",
        "You can read the NDA here. To proceed, please sign in the area below": "Az NDA-t itt tudod elolvasni. A folytatáshoz, kérjük, írd alá az alábbi területen",
        "Sign Below": "Írd alá az alábbi helyen",
        "Clear": "Törlés",
        "We have your photo. You can use this photo or click another one": "Megvan a fotód. Használhatod ezt, vagy rákattinthatsz egy másikra",
        "We'll use your camera to capture image of your Govt. ID": "A kamerád használatával rögzítjük hivatalos azonosító okmányod képét",
        "We have image of your Govt. ID. You can use this photo or click another one": "Megvan a hivatalos azonosító okmányod képet. Használhatod ezt, vagy rákattinthatsz egy másikra",
        "We'll use your camera to capture your photo for this visit": "A kamerád használatával rögzítjük fényképedet ezen látogatáshoz",
        "Please sign above to continue": "A folytatáshoz jelentkezz be felül",
        "Please agree to above.": "Kérjük, fogadd el a fentieket.",
        "You can only select {{count}} option": "Csak {{count}} opciót választhatsz ki",
        "You can only select {{count}} option_plural": "Csak {{count}} opciót választhatsz ki",
        "You can only select up to {{count}} option": "Maximum csak {{count}} opciót választhatsz ki",
        "You can only select up to {{count}} option_plural": "Maximum csak {{count}} opciót választhatsz ki",
        "You can only select {{v1}} to {{v2}} options": "Csak {{v1}}-{{v2}} opciót választhatsz ki",
        "Please fill the details": "Kérjük, add meg az adatokat",
        "No choices available": "Nincsenek elérhető választási lehetőségek",
        "This field is required": "Ez a mező kötelező",
        "Select atleast 1 value": "Legalább 1 értéket válassz ki",
        "Error": "Hiba",
        "Valid From": "Érvényes a következőtől:",
        "Valid Till": "Érvényes a következőig:",
        "Invite QR": "Meghívás QR-e",
        "Additional Info": "További infók",
        "info-checkIn-super-fast-venue": "A helyszínen történő szupergyors bejelentkezéshez itt beírhatod az adatokat.",
        "dont-fill-form-again": "Kérjük, ne töltsd ki még egyszer ezt az űrlapot, ha egyszer már kitöltötted.",
        "Domain": "Domén",
        "Change domain?": "Domén módosítása?",
        "Email Id": "E-mail",
        "Mobile Number": "Telefonszám",
        "Email ID": "E-mail",
        "Email": "E-mail",
        "Phone": "Telefonszám",
        "First Name": "Utónév",
        "Last Name": "Vezetéknév",
        "Become Member": "Légy tag",
        "Company Name": "Cégnév",
        "ID Type": "Azonosító típusa",
        "ID Number": "Azonosító száma",
        "Who do you wish to meet": "Kivel szeretnél találkozni",
        "Purpose of Visit": "Látogatás célja",
        "Arrival Instructions": "Érkezési instrukciók",
        "Destination": "Úticél",
        "Is Anyone Going to Escort you": "El fog kísérni valaki",
        "Escort Name": "Kísérő neve",
        "Escort ID": "Kísérő azonosítója",
        "Personal Item 1": "Személyes tárgy 1",
        "Personal Item 2": "Személyes tárgy 2",
        "Personal Item 3": "Személyes tárgy 3",
        "Personal Item 4": "Személyes tárgy 4",
        "Confirmation": "Megerősítés",
        "Address": "Cím",
        "Please fill following information": "Kérjük, add meg az alábbi információkat",
        "cannot be empty": "nem lehet üres",
        "This is not a valid value": "Ez nem egy érvényes érték",
        "This": "Ez",
        "is not valid": "nem érvényes",
        "Enter mobile number": "Mobiltelefonszám megadása",
        "Enter the name of employee you wish to meet": "Add meg a munkavállaló nevét, akivel találkozni szeretnél",
        "Enter your Company Name": "Add meg a céged nevét",
        "Enter your Email ID": "Add meg az e-mail-azonosítódat",
        "Enter your First Name": "Add meg az utónevedet",
        "Enter your Last Name": "Add meg a vezetéknevedet",
        "Enter your mobile number or email id": "Add meg a telefonszámodat vagy az e-mail-azonosítódat",
        "I": "Én",
        "This field cannot be empty": "Ez a mező nem lehet üres",
        "Please select an option": "Kérjük, válassz ki egy opciót",
        "Click here": "Kattints ide",
        "Unable to find someone": "Nem lehet megtalálni",
        "Unable to find member": "A tag nem található",
        "Confirm submission": "Beadvány megerősítése",
        "Contact Details": "Elérhetőségi adatok",
        "Please Enter your Email ID": "Kérjük, add meg az e-mail-azonosítódat",
        "Submit Information": "Információ elküldése",
        "Click here to read rules and regulations": "A szabályok és szabályozások elolvasásához kattints ide",
        "Select your purpose": "Célod kiválasztása",
        "accept the above terms and condition": "a fenti feltételek elfogadása",
        "Organization": "Szervezet",
        "You've been invited to": "Meg lettél hívva a következőhöz:",
        "Your information has been successfully submitted.": "Az információd beküldése sikeres.",
        "You can close this window now.": "Most bezárhatod ezt az ablakot.",
        "Please fill the following Information Fields": "Kérjük, töltsd ki a következő információs mezőket",
        "Invite Code" : "Meghívó kód",
        "Begin WebCheckin": "Kezdje a webes bejelentkezést",
        'Skip':'Ugrás',
        "Refuse": "Megtagadja",
        "Experience a super fast check in by completing the Web Check in process.":"Tapasztaljon meg egy szuper gyors bejelentkezést az internetes bejelentkezési folyamat befejezésével.",
        "QR Code can be used for Parking/Locker": "A QR -kód használható parkoláshoz/szekrényhez",
        "Navigate": "Hajózik",
        "Add to calendar": "Adja hozzá a naptárhoz",
        "Tap to close zoom": "Érintse meg a zoom bezárásához"
      }
}
