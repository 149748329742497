export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const ASYNC_TRANSFER_SUCCESSFUL = "ASYNC_TRANSFER_SUCCESSFUL";
export const UPDATE_SESSION = "UPDATE_SESSION";
export const FLUSH_SESSION_FROM_REDUX = "FLUSH_SESSION_FROM_REDUX";
export const IMAGE_CLICKED = 'IMAGE_CLICKED';
export const CLEAR_CURRENT_LOG = 'CLEAR_LOG';
export const GET_BADGE_SUCCESS = "GET_BADGE_SUCCESS";
export const UPDATE_CURRENT_LOG = "UPDATE_CURRENT_LOG";
export const LOG_TO_DB = "LOG_TO_DB";
export const ACTIVITY_LOGGED_SUCCESSFULLY = "ACTIVITY_LOGGED_SUCCESSFULLY";
export const PICTURE_UPLOAD_FAILED = "PICTURE_UPLOAD_FAILED";
export const UPDATE_WORKFLOW = "UPDATE_WORKFLOW";
export const SET_TOTAL_SCREENS = "SET_TOTAL_SCREENS";
export const SET_CONFIRMATION_SCREEN = "SET_CONFIRMATION_SCREEN";
export const UPDATE_WORKFLOW_DATA = "UPDATE_WORKFLOW_DATA";
export const UPDATE_MEETING_WITH = "UPDATE_MEETING_WITH";
export const UPDATE_ACTIVITY_FLAGS = "UPDATE_ACTIVITY_FLAGS";
export const UPDATE_LOG = "UPDATE_LOG";
export const ONBOARD_USER_SUCCESSFUL = "ONBOARD_USER_SUCCESSFUL";
export const ONBOARD_USER_FAILED = "ONBOARD_USER_FAILED";
export const UPDATE_DIMENSIONS = "UPDATE_DIMENSIONS";
export const UPDATE_PARAMS = "UPDATE_PARAMS";
export const CLEAR_PARAMS = "CLEAR_PARAMS";
export const CLEAR_VISITOR_LOG = "CLEAR_VISITOR_LOG";

export const UPDATE_PHONE_NUMBER = "UPDATE_PHONE_NUMBER";
export const UPDATE_INVITE_LOG = "UPDATE_INVITE_LOG";

