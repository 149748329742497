import { browserHistory, Route, Router } from 'react-router';
import React, { lazy, Suspense } from "react";
import Loader from "../components/Loader";
import { Provider } from "react-redux";
import store from "../common/utilities/storage/store";
import { Dimensions } from 'react-native';
import currentDimensions from '../common/actions/updateDimensions';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import cogoToast from 'cogo-toast';
import InviteDetails from "./inviteDetails";
import { getLanguage, setLanguage } from "../common/utilities/i18n/utils";
import { ClearSessionFromRedux } from '../common/actions/logoutActions';
import AxiosSingleton from '../common/utilities/network/AxiosSingleton';

const Badge = lazy(() => import('../screens/Badge/index'));
const WorkflowScreen = lazy(() => import('../veris-workflows/index'));
const WorkflowType = lazy(() => import('../veris-workflows/WorkflowType'));
const ActivityCompleted = lazy(() => import('../webCheckIn/ActivityCompleted/index'));

class App extends React.Component {
    constructor(params, context) {
        super(params, context);
        this.state = {};
        this.persistor = persistStore(store)
    }

    _getFilteredDimensions = (currentHeight, currentWidth) => {
        if (!currentHeight || !currentWidth) {
            let { height, width } = Dimensions.get('window');
            currentHeight = height;
            currentWidth = width;
        }
        if (currentWidth > 768) {
            currentWidth = 550
        }
        if (currentHeight > 1024) {
            currentHeight = 1024
        }
        return [currentHeight, currentWidth];
    }

    _handleWindowResize = () => {
        let { height, width } = Dimensions.get('window');
        this._getFilteredDimensions(height, width);
        let [currentHeight, currentWidth] = this._getFilteredDimensions();
        store.dispatch(currentDimensions(currentHeight, currentWidth));
    }

    updateStatus(event) {
        if (navigator.onLine) {
            cogoToast.success('You are back online!');
        } else {
            cogoToast.error('You are offline, please check you internet connection!');
        }
    }

    // [If session is interrupted]
    handleInitialUrlSave = () => {
        const queryParams = window.location.search || null;
        const isSessionActive = sessionStorage.getItem("sessionActive") || "false"
        const currentUrl = window.location.href;
        const savedUrl = localStorage.getItem('initialUrl');
        const appPathNames = ['workflowType', 'workflow', 'summary', 'badge', "page"];
        const isAppPath = appPathNames.some(path => currentUrl.includes(path));
        const host = window.location.origin + "/";

        // [if user goes to any app path directly from link then redirect to /]
        if (isAppPath) {
            if (isSessionActive == "false" && !savedUrl) {
                localStorage.removeItem('initialUrl');
                window.location.replace("/");
            }
            else {
                window.location.replace(savedUrl ? savedUrl : "/");
            }
        } else {
            // [if user is not on main link and there is no initial url in localstorage ot session is not started then save url to localstorage]
            if ((!savedUrl || isSessionActive == "false") && currentUrl !== host && queryParams) {
                localStorage.setItem('initialUrl', currentUrl);
            }

            // [if user refreshes the page]
            if ((savedUrl && currentUrl !== savedUrl && isSessionActive == "true") || !queryParams) {
                localStorage.removeItem('initialUrl')
                sessionStorage.removeItem('sessionActive'); // Clear session marker
                localStorage.clear();
                store.dispatch(ClearSessionFromRedux());
                AxiosSingleton.resetInstance();
                window.location.replace(savedUrl);
            }

            // [if there is not webcheckin url]
            if (currentUrl == host && isSessionActive == "true") {
                localStorage.removeItem('initialUrl');
            }
        }
        sessionStorage.setItem("sessionActive", "true")
    }

    handleTabClose = () => {
        // if (!navigator.sendBeacon) {
        // localStorage.clear();
        // localStorage.removeItem('initialUrl')
        // }
        sessionStorage.clear()
        sessionStorage.removeItem('sessionActive'); // Clear session marker
        store.dispatch(ClearSessionFromRedux());
        AxiosSingleton.resetInstance();
    };

    componentDidMount() {
        let locale = getLanguage();
        setLanguage(locale);
        this.handleInitialUrlSave();
        window.addEventListener('resize', this._handleWindowResize);
        window.addEventListener('online', this.updateStatus)
        window.addEventListener('offline', this.updateStatus)

        // Clear localStorage when tab is closed or refreshed
        window.addEventListener('beforeunload', this.handleTabClose);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._handleWindowResize);
        window.removeEventListener('online', this.updateStatus)
        window.removeEventListener('offline', this.updateStatus)

        // Clean up event listeners
        window.removeEventListener('beforeunload', this.handleTabClose);
    }

    render() {
        let [height, width] = this._getFilteredDimensions();
        this._handleWindowResize();
        return (
            <Provider store={store}>
                <PersistGate loading={<div>Loading...</div>} persistor={this.persistor}>
                    <Suspense fallback={<Loader />}>
                        <Router history={browserHistory} key={Math.random()}>
                            <Route path="/" component={InviteDetails}
                                details={this.state}
                                height={height}
                                width={width} />
                            <Route path="/workflowType" component={WorkflowType}
                                height={height}
                                width={width} />
                            <Route path="/workflow/page/:page_no" component={WorkflowScreen}
                                height={height}
                                width={width} />
                            <Route path="/summary" component={ActivityCompleted}
                                height={height}
                                width={width} />
                            <Route path="/badge" component={Badge}
                                height={height}
                                width={width} />
                        </Router>
                    </Suspense>
                </PersistGate>
            </Provider>
        )
    }
}

export default (App)
