import * as types from "../actions/actionTypes";
import _ from 'lodash';


export default function logReducer(state = {}, action) {
    switch (action.type) {
        case types.UPDATE_CURRENT_LOG:
        case types.UPDATE_WORKFLOW:
            state.visitor.check_in_data = action.data;
            return state;
        case types.UPDATE_LOG:
            return JSON.parse(JSON.stringify(_.merge(state, action.data)));
        case types.UPDATE_WORKFLOW_DATA:
            state.workflow_data = _.unionBy(action.data.workflow_data, state.workflow_data, 'id');
            return state;
        case types.UPDATE_ACTIVITY_FLAGS:
            let activity_flags = state.activity_flags || [];
            if (action.data?.field_id) {
                let unique = activity_flags.filter(item => item?.field_id !== action.data.field_id).concat([action.data])
                state.activity_flags = unique
            } else {
                state.activity_flags = [...activity_flags, ...action.data]
            }
            return state;
        case types.ACTIVITY_LOGGED_SUCCESSFULLY:
            return {
                ...state,
                ...action.data
            };
        case types.UPDATE_MEETING_WITH:
            if (state.visitor) {
                state.visitor.meeting_with = action.data
            }
            return state;
        case types.SET_TOTAL_SCREENS:
            return {
                ...state,
                ...action.data
            };
        case types.SET_CONFIRMATION_SCREEN:
            return {
                ...state,
                ...action.data
            };
        case types.CLEAR_VISITOR_LOG:
            return {};
        case types.FLUSH_SESSION_FROM_REDUX:
            return {};
        default:
            return state;
    }
}
