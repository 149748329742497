import * as types from "../actions/actionTypes";

export default function sessionReducer(state = {}, action) {
    switch (action.type) {
        case types.LOGIN_SUCCESSFUL:
        case types.LOGIN_FAILED:
        case types.ASYNC_TRANSFER_SUCCESSFUL:
            return {...state, ...action.data};
        case types.UPDATE_SESSION:
            return {...state, ...action.data};
        case types.FLUSH_SESSION_FROM_REDUX:
            return {};
        default:
            return state;
    }
}
