import React from "react";
import "../common/assets/css/global.css";
import translator from "../common/utilities/i18n";

const Loader = (props) => {
    return (
        <div className="overlay flex-row flex-center flex-justify-center">
            <div className='flex-col flex-center'>
                <div className="spinner-border veris_spinner text-primary" role="status"/>
                <br/>
                <span  className="text-primary" > {translator.t(props.loadingMessage)}</span>
            </div>
        </div>
    )
}

export default Loader;
