//THE BELOW KEYS ARE REQUIRED FOR ASYNC STORAGE
const GLOBAL_KEY = '@VerisTerminal2';
export const TOKEN = `${GLOBAL_KEY}:verisToken`;
export const PASSWORD = `${GLOBAL_KEY}:password`;
export const TERMINAL_LOGO = `${GLOBAL_KEY}:terminal_logo`;
export const ORG_LOGO = `${GLOBAL_KEY}:organization_logo`;
export const ORG_ID = `${GLOBAL_KEY}:organization_id`;
export const ORG_NAME = `${GLOBAL_KEY}:organization_name`;
export const TERMINAL_ID = `${GLOBAL_KEY}:terminal_id`;
export const THEME_PRIMARY_COLOR = `${GLOBAL_KEY}:theme_primary_color`;
export const THEME_SECONDARY_COLOR = `${GLOBAL_KEY}:theme_secondary_color`;
export const VENUE_ID = `${GLOBAL_KEY}:venue_id`;
export const SECONDARY_TEXT_COLOR = `${GLOBAL_KEY}:"secondary_text_color"`;
export const WELCOME_MESSAGE = `${GLOBAL_KEY}:welcome_message`;
export const VENUE_NAME = `${GLOBAL_KEY}:venue_name`;
export const ENABLE_BADGE_PRINT = `${GLOBAL_KEY}:enable_visitor_badge_print`;
export const DYNAMIC_HOSTNAME = `${GLOBAL_KEY}:dynamic_hostname`;
export const ENABLE_VERIS_SCANNER = `${GLOBAL_KEY}:enable_veris_scanner`;
export const ALLOW_ANONYMOUS_GUESTS = `${GLOBAL_KEY}:allow_anonymous_guests`;
export const ALLOW_EMPLOYEE = `${GLOBAL_KEY}:allow_employee`;
export const ALLOW_GUEST = `${GLOBAL_KEY}:allow_guest`;
export const ALLOW_WALKIN_VISITORS = `${GLOBAL_KEY}:allow_walk_in_visitors`;
export const SKIP_TERMINAL_FORM = `${GLOBAL_KEY}:skip_terminal_form`;
export const PRINTER_ADDRESS = `${GLOBAL_KEY}:printer_address`;
export const PRINTER_TYPE = `${GLOBAL_KEY}:printer_type`;
export const CREATED_AT_GT = `${GLOBAL_KEY}:created_at_gt`;
export const LAST_ACTIVITY_URL = `${GLOBAL_KEY}:last_activity_url`;
export const FORMS = `${GLOBAL_KEY}:forms`;
export const VERIFY_OTP = `${GLOBAL_KEY}:verify_otp`;
export const FORM_RULES = `${GLOBAL_KEY}:form_rules`;
export const SKIP_OTP_TIME = `${GLOBAL_KEY}:skip_otp_time`;
export const EXIT_MESSAGE = `${GLOBAL_KEY}:exit_message`;
export const WHOM_TO_MEET_TEXT = `${GLOBAL_KEY}:whom_to_meet_text`;
export const ADDITIONAL_INFO_MANDATORY = `${GLOBAL_KEY}:additional_info_mandatory`;
export const ADDITIONAL_INFO_FIELD = `${GLOBAL_KEY}:additional_info_field`;
export const WHOM_TO_MEET_MANDATORY = `${GLOBAL_KEY}:whom_to_meet_mandatory`;
export const WHOM_TO_MEET_FIELD = `${GLOBAL_KEY}:whom_to_meet_field`;
export const ADDITIONAL_INFO_TEXT = `${GLOBAL_KEY}:additional_info_text`;
export const IS_OFFLINE_MODE = `${GLOBAL_KEY}:is_offline_mode`;
export const TENANT_LIST = `${GLOBAL_KEY}:tenant_list`;
export const AUTO_MONITOR_NETWORK = `${GLOBAL_KEY}:auto_monitor_network`;
export const NDA_PDF = `${GLOBAL_KEY}:nda_pdf`;
export const BADGE_TEMPLATE = `${GLOBAL_KEY}:badge_template`;
export const BADGE_ORIENTATION = `${GLOBAL_KEY}:badge_orientation`;
export const BACKGROUND_IMAGE = `${GLOBAL_KEY}:background_image`;
export const POWERED_BY_IMAGE = `${GLOBAL_KEY}:powered_by_image`;
export const SYNC_LOGS = `${GLOBAL_KEY}:sync_logs`;
export const ADMIN_EMAIL = `${GLOBAL_KEY}:admin_email`;
export const CUSTOM_BRANDING_IMAGE = `${GLOBAL_KEY}:custom_branding_image`;
export const WALKIN_ACCESS_TIME = `${GLOBAL_KEY}:"default_walkin_access_time"`;
export const DO_NOT_SHOW_OPTIONAL_PLACEHOLDERS = `${GLOBAL_KEY}:"do_not_show_optional_placeholders"`;
export const ENABLE_FACE_DETECTION = `${GLOBAL_KEY}:"enable_face_detection"`;
export const GUARD_PIN = `${GLOBAL_KEY}:"guard_pin"`;
export const CLIENT_ID_SOURCE = `${GLOBAL_KEY}:"client_id_source"`;
export const ENABLE_STRICT_CHECK_IN_OUT = `${GLOBAL_KEY}:"enable_strict_check_in_out"`;
export const BASE_URL = `${GLOBAL_KEY}:"base_url"`;
export const ACCESSORIES = `${GLOBAL_KEY}:"accessories"`;
export const HIERARCHY_INVITES = `${GLOBAL_KEY}:"hierarchy_invites"`;
export const TOKEN_EXPIRY_TIME = `${GLOBAL_KEY}:"token_expiry_time"`;
export const LANGUAGE = `${GLOBAL_KEY}:"language"`;

export const ALL_KEYS = [
    BASE_URL,
    TOKEN,
    LANGUAGE,
    ORG_ID,
    ENABLE_FACE_DETECTION,
    WALKIN_ACCESS_TIME,
    DO_NOT_SHOW_OPTIONAL_PLACEHOLDERS,
    THEME_SECONDARY_COLOR,
    BADGE_ORIENTATION,
    POWERED_BY_IMAGE,
    PRINTER_TYPE,
    CUSTOM_BRANDING_IMAGE,
    SYNC_LOGS,
    BACKGROUND_IMAGE,
    VERIFY_OTP,
    PRINTER_ADDRESS,
    TERMINAL_LOGO,
    ORG_LOGO,
    EXIT_MESSAGE,
    ORG_NAME,
    TERMINAL_ID,
    THEME_PRIMARY_COLOR,
    VENUE_ID, WELCOME_MESSAGE,
    VENUE_NAME,
    ENABLE_BADGE_PRINT,
    DYNAMIC_HOSTNAME,
    ENABLE_VERIS_SCANNER,
    ALLOW_ANONYMOUS_GUESTS,
    ALLOW_EMPLOYEE,
    ALLOW_GUEST,
    ALLOW_WALKIN_VISITORS,
    SKIP_TERMINAL_FORM,
    CREATED_AT_GT,
    LAST_ACTIVITY_URL,
    FORMS,
    FORM_RULES,
    SKIP_OTP_TIME,
    ADDITIONAL_INFO_TEXT,
    WHOM_TO_MEET_FIELD,
    WHOM_TO_MEET_MANDATORY,
    ADDITIONAL_INFO_FIELD,
    ADDITIONAL_INFO_MANDATORY,
    WHOM_TO_MEET_TEXT,
    IS_OFFLINE_MODE,
    TENANT_LIST,
    NDA_PDF,
    AUTO_MONITOR_NETWORK,
    BADGE_TEMPLATE,
    ADMIN_EMAIL,
    GUARD_PIN,
    CLIENT_ID_SOURCE,
    ACCESSORIES,
    HIERARCHY_INVITES,
    SECONDARY_TEXT_COLOR,
    TOKEN_EXPIRY_TIME,
    ENABLE_STRICT_CHECK_IN_OUT
];
