export const SINGLE_LINE_TEXT = 1;
export const MULTI_LINE_TEXT = 2;
export const EMAIL = 3;
export const CHECKBOX = 4;
export const DROP_DOWN = 6;
export const MULTI_SELECT = 7;
export const RADIO_BUTTON = 8;
export const IMAGE = 9;
export const DATE = 10;
export const DATE_TIME = 11;
export const NUMBER = 13;
export const TIME = 15;
export const PHONE = 16;
export const RATING = 17;
export const PDF = 18;
export const PDF_SIGN = 19;
export const SIGN = 20;
export const IMAGE_CARD = 21;
export const DYNAMIC = 22;
export const BADGE = 23;
export const ACTIVITY_SUMMARY = 24;
export const INTERACTIVE_FIELD = 28;
export const BARCODE_SCAN = 25;
export const WEBVIEW = 29;
export const VIDEO_PLAY = 31;


