import React from "react";
import moment from "moment";
import ICalendarLink from "react-icalendar-link";
import "font-awesome/css/font-awesome.min.css";
import "./styles.css";
import { View, Dimensions, TouchableOpacity, Text } from "react-native";
import googleCalendarIcon from "../../../common/assets/webcheckinIcons/googleCalendarIcon.svg";
import yahooIcon from "../../../common/assets/webcheckinIcons/yahooIcon.svg";
import outlookIcon from "../../../common/assets/webcheckinIcons/outlookIcon.svg";
import otherIcon from "../../../common/assets/webcheckinIcons/otherIcon.svg";
import messageIcon from "../../../common/assets/webcheckinIcons/messageIcon.svg";
import closeIcon from "../../../common/assets/webcheckinIcons/closeIcon.svg";

function Calendar(props) {
  function getRandomKey() {
    var n = Math.floor(Math.random() * 999999999999).toString();
    return new Date().getTime().toString() + "_" + n;
  }

  function formatTime(date) {
    var formattedDate = moment.utc(date).format("YYYYMMDDTHHmmssZ");
    return formattedDate.replace("+00:00", "Z");
  }

  let arr = [props.hostEmail, props.guestEmail];

  const event = {
    title: `Meeting with ${props.HostName} ${
      !props.agenda ? "" : "| " + props.agenda
    }`,
    description: "",
    startTime: moment(props.startTime).toISOString(),
    endTime: moment(props.endTime).toISOString(),
    location: `${props.venue}, ${props.Address}`,
    attendees: [
      `${props.HostName} <${props.hostEmail}>`,
      `${props.guest} <${props.guestEmail}>`,
    ],
  };

  let googleUrl = "https://calendar.google.com/calendar/render";
  googleUrl += "?action=TEMPLATE";
  googleUrl +=
    "&dates=" + formatTime(moment(props.startTime).tz(props.venue_timeZone));
  googleUrl += "/" + formatTime(moment(props.endTime).tz(props.venue_timeZone));
  googleUrl +=
    "&location=" + encodeURIComponent(`${props.venue}, ${props.Address}`);
  googleUrl +=
    "&text=" +
    encodeURIComponent(
      `Meeting with ${props.HostName} ${!props.agenda ? "" : "| " + props.agenda}`
    );
  googleUrl += "&details=" + encodeURIComponent("");
  googleUrl += "&add=" + encodeURIComponent(props.hostEmail);
  googleUrl += "&add=" + encodeURIComponent(props.guestEmail);

  let yahooURL = "https://calendar.yahoo.com/?v=60&view=d&type=20";
  yahooURL +=
    "&title=" +
    encodeURIComponent(
      `Meeting with ${props.HostName} ${!props.agenda ? "" : "| " + props.agenda}`
    );
  yahooURL += "&st=" + moment(props.startTime).format("YYYYMMDDTHHmmssZ");
  yahooURL += "&ET=" + moment(props.endTime).format("YYYYMMDDTHHmmssZ");
  yahooURL += "&desc=" + encodeURIComponent("");
  yahooURL +=
    "&in_loc=" + encodeURIComponent(`${props.venue}, ${props.Address}`);
  yahooURL += "&inv_list=" + encodeURIComponent(arr);

  let outlookURL = "https://outlook.live.com/owa/?rru=addevent";
  outlookURL += "&startdt=" + moment(props.startTime).toISOString();
  outlookURL += "&enddt=" + moment(props.endTime).toISOString();
  outlookURL +=
    "&subject=" +
    encodeURIComponent(
      `Meeting with ${props.HostName} ${!props.agenda ? "" : "| " + props.agenda}`
    );
  outlookURL +=
    "&location=" + encodeURIComponent(`${props.venue}, ${props.Address}`);
  outlookURL += "&body=" + encodeURIComponent("");
  outlookURL += "&uid=" + getRandomKey();
  outlookURL += "&path=/calendar/view/Month";
  outlookURL += "&to=" + arr;

  const iconStyle = {
    position: "relative",
    bottom: "3px",
    right: "4px",
  };
  // console.log(props.agenda, !props.agenda, 'agenda')

  const windowWidth = Dimensions.get("window").width;

  const calendarOptions = [
    {
      title: "Google",
      icon: googleCalendarIcon,
      alt: "google_icon",
      href: googleUrl,
    },
    {
      title: "Yahoo",
      icon: yahooIcon,
      alt: "yahoo_icon",
      href: yahooURL,
    },
    {
      title: "Outlook",
      icon: outlookIcon,
      alt: "outlook_icon",
      href: outlookURL,
    },
  ];

  return (
    <div
      style={{
        height: "100vh",
        width: windowWidth < 575 ? "100%" : 550,
        position: "fixed",
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.2)",
        zIndex: 999,
        overflow: "hidden",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ borderRadius: 8, backgroundColor: "#FFFFFF", padding: 20 }}>
        {/* [Header] */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div />

          <img
            alt="msg_icon"
            src={messageIcon}
            style={{ height: 48, width: 48 }}
          />

          <TouchableOpacity style={{ height: 24, width: 24 }} onPress={() => props.setCalendarModalOpen(false)} >
            <img
              alt="close_icon"
              src={closeIcon}
              style={{ height: "100%", width: "100%" }}
            />
          </TouchableOpacity>
        </View>

        <View style={{ marginTop: 20 }}>
          <Text
            style={{
              fontSize: "24px",
              fontFamily: "Gilroy-Medium",
              lineHeight: "16px",
              color: "#101828",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Add to Calendar
          </Text>

          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Gilroy-Medium",
              lineHeight: "16px",
              color: "#475467",
              fontWeight: 400,
              marginTop: 10,
              textAlign: "center",
            }}
          >
            Please select below option to add this event
          </Text>
        </View>

        {/* [Options] */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {calendarOptions?.map((item, index) => (
            <a
              href={item.href}
              target="_blank"
              key={index}
              style={{
                textDecoration: "none",
                height: 100,
                width: "46%",
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #708E8C",
                borderRadius: 8,
              }}
            >
              <img
                alt={item.alt}
                src={item.icon}
                style={{ height: 40, width: 40 }}
              />
              <Text
                style={{
                  fontSize: "20px",
                  fontFamily: "Gilroy-Medium",
                  lineHeight: "16px",
                  color: "#2F4644",
                  fontWeight: "600",
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                {item.title}
              </Text>
            </a>
          ))}

          <View
            style={{
              width: "46%",
              marginTop: 20,
            }}
          >
            <ICalendarLink event={event}>
              <div
                style={{
                  height: 100,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #708E8C",
                  borderRadius: 8,
                }}
              >
                <img
                  alt="other_icon"
                  src={otherIcon}
                  style={{ height: 40, width: 40 }}
                />
                <Text
                  style={{
                    fontSize: "20px",
                    fontFamily: "Gilroy-Medium",
                    lineHeight: "16px",
                    color: "#2F4644",
                    fontWeight: "600",
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  Other
                </Text>
              </div>
            </ICalendarLink>
          </View>
        </View>
      </div>
    </div>
  );
}

export default Calendar;
