import React from "react";
import { QRCode } from "react-qr-svg";
import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import translator from "../../../common/utilities/i18n/";

function QrModal(props) {
  const windowWidth = Dimensions.get("window").width;
  return (
    <div
      style={{
        height: "100vh",
        width: windowWidth < 575 ? "100%" : 550,
        position: "fixed",
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.2)",
        zIndex: 999,
        overflow: "hidden",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: 25,
          backgroundColor: "#FFFFFF",
          padding: 20,
          width: 254,
        }}
      >
        {/* [Header] */}
        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <QRCode
            value={props.qr_code}
            style={{
              width: "100%",
            }}
          />

          <TouchableOpacity
            style={{
              height: 40,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D3DDDC",
              borderRadius: 5,
              marginTop: 25,
            }}
            onPress={() => setTimeout(() => props.setQrModalOpen(false), 100)}
          >
            <Text
              style={{
                fontSize: "12px",
                fontFamily: "Gilroy-Medium",
                fontWeight: "600",
                color: "#2F4644",
              }}
            >
              {translator.t("Tap to close zoom")}
            </Text>
          </TouchableOpacity>
        </View>
      </div>
    </div>
  );
}

export default QrModal;
