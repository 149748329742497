const initialState = {
  face: false,
  loader: false,
};

const detection = (state = initialState, action) => {
  switch (action.type) {
    case "FACE_DETECTED":
      return {
        ...state,
        face: action.payload,
      };
    case "LOADER":
      console.log(action);
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return { ...state };
  }
};

export default detection;
