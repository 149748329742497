import * as types from "./actionTypes";

export default function updateDimensions(height, width) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_DIMENSIONS,
            data: {height, width}
        });

    }
}


