import translator from "./index";
import _ from "lodash";
import axios from 'axios'
import * as moment from "moment";
import {getDomainUrl} from "../../../veris-workflows/utils/utils";

export function interpolateTranslatedString(
    text = '',
    valueToInsert = '',
    insertAfterWord = ''
) {
    let translatedText = translator.t(text)
    let stringParts = text.split(' ');
    stringParts = stringParts.map(word => translator.t(word))
    console.warn(stringParts)
    if (insertAfterWord > stringParts.length || !valueToInsert || !insertAfterWord)
        return translatedText;

    let indexToFetch = insertAfterWord >= 1 ? insertAfterWord - 1 : -1;
    let updatedStringWithVaue = '',
        modifiedStringWithValue = '';
    if (indexToFetch < 0) {
        updatedStringWithVaue = valueToInsert + ' ' + stringParts[indexToFetch + 1];
        stringParts[indexToFetch + 1] = updatedStringWithVaue

    } else {
        updatedStringWithVaue = stringParts[indexToFetch] + ' ' + valueToInsert;
        stringParts[indexToFetch] = updatedStringWithVaue
    }
    modifiedStringWithValue = stringParts.join(' ');
    return modifiedStringWithValue
}

export async function updateWorkflowTranslations(workflowArray) {
    if (!_.isEmpty(workflowArray))
        return Promise.all(
            workflowArray.map(async (workflow) => {
                let url = workflow.language_info?.default;
                url = getDomainUrl(url);
                console.log(url)
                if (!_.isEmpty(url)) {
                    try {
                        let response = await axios.get(url)
                        console.log('TRANSLATIONS', response.data)
                        if (response.status === 200 && response.data) {
                            for (let lang in response.data) {
                                translator.addResources(lang, 'translation', response.data[lang])
                            }
                        }
                    } catch (e) {
                        console.log('error while updating translations', e)
                    }
                }
                return workflow;
            }));
}

export function setLanguage(locale, callback) {
    translator.changeLanguage(locale, (err, t) => {
        if (err) return console.log('something went wrong loading', err);
        t('key'); // -> same as i18next.t
        moment.locale(locale);
        try {
            if (!_.isEmpty(locale)) {
                localStorage.setItem('language', locale)
            }
        } catch (e) {
            console.log('Could not set language', e)
        }
    })
        .then(() => {
            if (callback)
                setTimeout(() => callback && callback(), 3300);
        })
        .catch(e => {
            console.log('Could not set language', e)

        })
}

export const getLanguage = () => {
    let locale = 'en';
    console.log('.....', locale)
    if (!locale)
        locale = getSystemLocale();
    return locale
};

export const getSystemLocale = () => {
    return navigator.language
}


export function getLabelTranslationKey(field) {
    if(field && field.id) {
        return translator.exists(`${field.id}_label`) ? `${field.id}_label` : field.label
    }
    return field
}

// export function getPlaceholderTranslationKey(field) {
//     return `${field.id}_placeholder`
// }

export function getPlaceholderTranslationKey(field) {
    if(field && field.id) {
        return translator.exists(`${field.id}_placeholder`) ? `${field.id}_placeholder` : field.placeholder_text
    }
    return field
}
