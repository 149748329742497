import React from "react";
import { Button } from "antd";
import navigateIcon from "../../../common/assets/webcheckinIcons/navigateIcon.svg";
import { Dimensions } from "react-native";
import translator from "../../../common/utilities/i18n/";

// const NavigationIconURl = process.env.PUBLIC_URL + "/location.svg";

function Navigation(props) {
  let lat = props?.position?.latitude || "";
  let lng = props?.position?.longitude || "";

  const windowWidth = Dimensions.get("window").width;
  return (
    <Button
      style={
        windowWidth < 575
          ? {
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
              color: "#4F7471",
              fontSize: "12px",
              fontWeight: 700,
              whiteSpace: "normal",
              height: 38,
              borderColor: "#B2C3C1",
              borderWidth: 1,
              borderRadius: 5,
              width: "100%",
              padding: "0 12px",
              fontFamily: "Gilroy-Medium"
            }
          : {
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
              color: "#4F7471",
              fontSize: "20px",
              fontWeight: 700,
              whiteSpace: "normal",
              height: 52,
              borderColor: "#B2C3C1",
              borderWidth: 1,
              borderRadius: 5,
              width: "100%",
              padding: "0 12px",
              fontFamily: "Gilroy-Medium"
            }
      }
      icon={
        <img
          alt="navigate_icon"
          src={navigateIcon}
          style={
            windowWidth < 575
              ? { height: 14, width: 14, marginRight: 10 }
              : { height: 18, width: 18, marginRight: 12 }
          }
        />
      }
      onClick={() =>
        window.open(`https://maps.google.com?q=${lat},${lng}`, "_blank")
      }
    >
      {/* {`Navigate To ${props?.org_name}`} */}
      {translator.t("Navigate")}
    </Button>
  );
}

export default Navigation;
