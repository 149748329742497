import * as _ from 'lodash';
import {PhoneNumberUtil} from "google-libphonenumber";
import * as ft from "../../veris-workflows/utils/fieldTypes";

export function getMidNightTimeStamp() {
    let currentDate = new Date();
    currentDate.setHours(0)
    currentDate.setMinutes(1)
    currentDate.setSeconds(0)
    currentDate.setMilliseconds(0)

    return currentDate.getTime();
}
export function transformInviteWorkflowData(workflows) {
    let lastActivityData = [];
    if (!_.isEmpty(workflows) && workflows.screens) {
        let transformedWorkflowData = [];
        workflows.screens.forEach((screenObject) => {
            screenObject.screen.form_fields.forEach((fieldObject) => {
                transformedWorkflowData.push(fieldObject);
            })
        });

        lastActivityData = transformedWorkflowData;
    }
    return lastActivityData
}

export function getValueFromWorkflowDataById(workflow_data, id) {
    let value = ''
    if (id && workflow_data && workflow_data.length > 0 && Array.isArray(workflow_data)) {
        workflow_data.forEach((field) => {
            if (field.id === id) {
                value = field.value || ''
            }

        })
    }
    return value

}

export function getValueFromWorkflowData(workflow_data, currentField) {
    let value = '';
    if (currentField && workflow_data && workflow_data.length > 0) {
        workflow_data.forEach((field) => {
            if (field.id === currentField.id) {
                value = field.value || ''
            } else if (field.field_alias && currentField.field_alias && field.field_alias === currentField.field_alias) {
                value = field.value || ''
            }

        })
    }
    return value
}

export function updateValueFromVisitor(visitor, field, fieldValue) {
    let value = fieldValue;
    if (!_.isEmpty(visitor) && visitor.hasOwnProperty(field.field_code) && !_.isEmpty(visitor[field.field_code])) {
        value = visitor[field.field_code] || ''
    }
    if (!_.isEmpty(visitor) && !_.isEmpty(visitor.mobile_number) && field.field_code === "phone_no") {
        value = visitor.mobile_number || ''
    }
    if (!_.isEmpty(visitor) && !_.isEmpty(visitor.picture) && field.field_code === "profile_picture") {
        value = visitor.picture || ''
    }
    return value
}

export function returnErrorMessage(response) {
    let errorMessage = 'Something went wrong';
    try {
        for (let key in response) {
            if (response.hasOwnProperty(key) && typeof response[key] === 'string') {
                errorMessage = response[key];
            } else {
                return returnErrorMessage(response[key]);
            }
            break;
        }
    } catch (e) {

    }
    return errorMessage;
}

export function getWorkflowDataToFill(activityLog) {
    console.log('UUAUAU', activityLog)

    if (!_.isEmpty(activityLog.user_pass) && !_.isEmpty(activityLog.user_pass[0].workflows)
        && activityLog.user_pass[0].workflows.workflow_data && activityLog.user_pass[0].workflows.workflow_data.length > 0) {
        console.warn("====invite", activityLog.user_pass[0].workflows.workflow_data)
        return activityLog.user_pass[0].workflows.workflow_data
    }

    if (!_.isEmpty(activityLog.user_pass) && !_.isEmpty(activityLog.user_pass[0].workflows)) {
        return transformInviteWorkflowData(activityLog.user_pass[0].workflows[0] || activityLog.user_pass[0].workflows);
    }
    if (!_.isEmpty(activityLog) && !_.isEmpty(activityLog.lastWorkflowData) && !_.isEmpty(activityLog.lastWorkflowData[0])
        && !_.isEmpty(activityLog.lastWorkflowData[0].data)) {
        console.log('here')
        return activityLog.lastWorkflowData[0].data;
    }
    if (!_.isEmpty(activityLog) && !_.isEmpty(activityLog.lastWorkflowData) && activityLog.lastWorkflowData.length > 0) {
        return activityLog.lastWorkflowData;
    }
    return [];
}

export function getVisitorObjectFromInvite(response) {
    let visitor = {
        first_name: '',
        last_name: '',
        picture: '',
        mobile_number: '',
        email: '',
        emergency_contact_name: '',
        emergency_contact: '',
        gender: '',
        unique_id: ''
    }
    let visitorFromWorkflowData;

    if (!_.isEmpty(response.data.user_pass)) {
        visitor.first_name = response.data.user_pass[0].guest.first_name || "";
        visitor.last_name = response.data.user_pass[0].guest.last_name || "";
        visitor.mobile_number = response.data.user_pass[0].guest.contactinfo && response.data.user_pass[0].guest.contactinfo.phones[0] && response.data.user_pass[0].guest.contactinfo.phones[0].dial_code + response.data.user_pass[0].guest.contactinfo.phones[0].mobile_number || "";
        visitor.email = response.data.user_pass[0].guest.contactinfo && response.data.user_pass[0].guest.contactinfo.emails[0] && response.data.user_pass[0].guest.contactinfo.emails[0].email || "";
    }
    if (!_.isEmpty(response.data.user_pass) && !_.isEmpty(response.data.user_pass[0].workflows)
        && response.data.user_pass[0].workflows.workflow_data && response.data.user_pass[0].workflows.workflow_data.length > 0) {
        visitorFromWorkflowData = getVisitorObjectFromLastActivityOrWorkflow(response.data.user_pass[0].workflows.workflow_data)
    }
    console.warn("visitorFromWorkflowDatavisitorFromWorkflowData", visitorFromWorkflowData)
    visitor = fillVisitorObjectWithPreference(visitor, visitorFromWorkflowData)
    return visitor
}

export function populateVisitorObjectFromLastActivityOrWorkflow(lastActivityData, visitor) {
    if (!_.isEmpty(lastActivityData) && Array.isArray(lastActivityData)) {
        for (let obj of lastActivityData) {
            if (obj.field_type === ft.IMAGE && !_.isEmpty(obj.value) && _.isEmpty(visitor.picture)) {
                visitor.picture = obj.value;
            } else if (obj.field_code === "first_name" && !_.isEmpty(obj.value) && _.isEmpty(visitor.first_name)) {
                visitor.first_name = obj.value;
            } else if (obj.field_code === "last_name" && !_.isEmpty(obj.value) && _.isEmpty(visitor.last_name)) {
                visitor.last_name = obj.value;
            } else if (obj.field_code === "email" && !_.isEmpty(obj.value) && _.isEmpty(visitor.email)) {
                visitor.email = obj.value;
            } else if (obj.field_code === "phone_no" && !_.isEmpty(obj.value) && _.isEmpty(visitor.mobile_number)) {
                visitor.mobile_number = obj.value;
            } else if (obj.field_code === "gender" && !_.isEmpty(obj.value) && _.isEmpty(visitor.gender)) {
                visitor.gender = obj.value;
            } else if (obj.field_code === "emergency_person_name" && !_.isEmpty(obj.value) && _.isEmpty(visitor.emergency_contact_name)) {
                visitor.emergency_contact_name = obj.value;
            } else if (obj.field_code === "emergency_person_contact" && !_.isEmpty(obj.value) && _.isEmpty(visitor.emergency_contact)) {
                visitor.emergency_contact = obj.value;
            }
        }
        return visitor;
    }

}

export function getVisitorObjectFromMember(response) {
    let visitor = {
        first_name: '',
        last_name: '',
        picture: '',
        mobile_number: '',
        email: '',
        emergency_contact_name: '',
        emergency_contact: '',
        gender: '',
        unique_id: ''
    };

    if (!_.isEmpty(response.data.member)) {
        visitor.first_name = response.data.member.first_name || "";
        visitor.last_name = response.data.member.last_name || "";
    }
    if (!_.isEmpty(response.data.member) &&
        !_.isEmpty(response.data.member.contacts) &&
        isValidPhone(response.data.member.contacts.phone)) {
        visitor.mobile_number = response.data.member.contacts.phone;

    }
    if (!_.isEmpty(response.data.member) &&
        !_.isEmpty(response.data.member.contacts) &&
        isValidEmail(response.data.member.contacts.email)) {
        visitor.email = response.data.member.contacts.email;

    }
    if (!_.isEmpty(response.data.member) &&
        !_.isEmpty(response.data.member.members_meta) && response.data.member.members_meta.emergency_contact) {
        visitor.emergency_contact_name = response.data.member.members_meta.emergency_contact.name;
        visitor.emergency_contact = response.data.member.members_meta.emergency_contact.contact;

    }
    if (!_.isEmpty(response.data.member) &&
        !_.isEmpty(response.data.member.members_meta) && response.data.member.members_meta.unique_id) {
        visitor.unique_id = response.data.member.members_meta.unique_id;
    }
    if (!_.isEmpty(response.data.member) &&
        !_.isEmpty(response.data.member.members_meta) && response.data.member.members_meta.gender) {
        visitor.gender = response.data.member.members_meta.gender;
    }

    return visitor
}

export function transformLastWorkflowDataToNewFormat(response) {
    let lastActivityData = null;
    if (response.data && response.data.data &&
        response.data.data.visitor &&
        response.data.data.visitor.check_in_data &&
        response.data.data.visitor.check_in_data.screens) {
        let transformedWorkflowData = [];
        response.data.data.visitor.check_in_data.screens.forEach((screenObject) => {
            screenObject.screen.form_fields.forEach((fieldObject) => {
                transformedWorkflowData.push({
                    "id": fieldObject.id,
                    "label": fieldObject.label,
                    "value": fieldObject.value.name || fieldObject.value,
                    "field_code": fieldObject.field_code,
                    "field_type": fieldObject.field_type
                })
            })
        });

        lastActivityData = transformedWorkflowData;
    } else if (response.data && response.data.data) {
        lastActivityData = response.data.data
    } else if (response.data && response.data.length > 0) {
        lastActivityData = response.data
    } //note: @GG, why is this condition required?
    return lastActivityData;
}

export function getVisitorObjectFromLastActivityOrWorkflow(lastActivityData) {
    let visitor = {
        first_name: '',
        last_name: '',
        picture: '',
        mobile_number: '',
        email: '',
        emergency_contact_name: '',
        emergency_contact: '',
        gender: '',
        unique_id: ''
    }


    if (!_.isEmpty(lastActivityData) && Array.isArray(lastActivityData)) {
        for (let obj of lastActivityData) {
            if (obj.field_type === ft.IMAGE && !_.isEmpty(obj.value)) {
                visitor.picture = obj.value;
            } else if (obj.field_code === "first_name" && !_.isEmpty(obj.value)) {
                visitor.first_name = obj.value;
            } else if (obj.field_code === "last_name" && !_.isEmpty(obj.value)) {
                visitor.last_name = obj.value;
            } else if (obj.field_code === "email" && !_.isEmpty(obj.value)) {
                visitor.email = obj.value;
            } else if (obj.field_code === "phone_no" && !_.isEmpty(obj.value)) {
                visitor.mobile_number = obj.value;
            } else if (obj.field_code === "gender" && !_.isEmpty(obj.value)) {
                visitor.gender = obj.value;
            } else if (obj.field_code === "emergency_person_name" && !_.isEmpty(obj.value)) {
                visitor.emergency_contact_name = obj.value;
            } else if (obj.field_code === "emergency_person_contact" && !_.isEmpty(obj.value)) {
                visitor.emergency_contact = obj.value;
            } else if (obj.field_code === "unique_id" && !_.isEmpty(obj.value)) {
                visitor.unique_id = obj.value;
            }
        }

    }
    return visitor


}

export function fillVisitorObjectWithPreference(existingVisitor, newVisitor) {
    let visitor = {
        first_name: '',
        last_name: '',
        picture: '',
        mobile_number: '',
        email: '',
        emergency_contact_name: '',
        emergency_contact: '',
        gender: '',
        unique_id: ''
    }

    if (!newVisitor) {
        return existingVisitor
    }

    visitor.first_name = _.isEmpty(existingVisitor.first_name) ? newVisitor.first_name : existingVisitor.first_name
    visitor.last_name = _.isEmpty(existingVisitor.last_name) ? newVisitor.last_name : existingVisitor.last_name
    visitor.picture = _.isEmpty(existingVisitor.picture) ? newVisitor.picture : existingVisitor.picture
    visitor.mobile_number = _.isEmpty(existingVisitor.mobile_number) ? newVisitor.mobile_number : existingVisitor.mobile_number
    visitor.email = _.isEmpty(existingVisitor.email) ? newVisitor.email : existingVisitor.email
    visitor.emergency_contact_name = _.isEmpty(existingVisitor.emergency_contact_name) ? newVisitor.emergency_contact_name : existingVisitor.emergency_contact_name
    visitor.emergency_contact = _.isEmpty(existingVisitor.emergency_contact) ? newVisitor.emergency_contact : existingVisitor.emergency_contact
    visitor.gender = _.isEmpty(existingVisitor.gender) ? newVisitor.gender : existingVisitor.gender
    visitor.unique_id = _.isEmpty(existingVisitor.unique_id) ? newVisitor.unique_id : existingVisitor.unique_id

    return visitor

}

export function getContactData(contactInfo) {
    let dialCode, mobileNumber, email;

    if (!contactInfo)
        return {};

    if (!_.isEmpty(contactInfo.emails)) {
        email = contactInfo.emails[0].email;
    }

    if (!_.isEmpty(contactInfo.phones)) {
        // FIXME: find better way to convert int to string
        dialCode = contactInfo.phones[0].dial_code + "";
        mobileNumber = contactInfo.phones[0].mobile_number;
        if (!_.includes(dialCode, "+")) {
            // FIXME: find better way for concatenation
            dialCode = "+" + dialCode;
        }
    }
    console.log("dialcode", dialCode, "mobileNumber", mobileNumber, "email", email);

    return {dialCode, mobileNumber, email};
}

export function isValidEmail(value) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
}
export function validateEmail(value) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}

export function isValidPhone(value) {
    let isValidPhone = false;
    if(value && !value?.includes("@")){
        let phoneUtil = PhoneNumberUtil.getInstance();
    try {
        let number = phoneUtil.parse(`+${value}`);
        isValidPhone = phoneUtil.isValidNumber(number);
    } catch (error) {
        console.log("Error", error);
    }
    }
    return isValidPhone;
}


export async function downloadImageAndReturnPath(url, token) {
    // return await RNFetchBlob
    //     .config({
    //         session: 'login',
    //         timeout: 15000,
    //         fileCache: true,
    //         appendExt: 'png',
    //         path: imageDirectory + `${UUID.create().hex}.png`
    //     })
    //     .fetch('GET', encodeURI(url), {
    //         'Authorization': `Gatekeeper ${token}`
    //     }).then((res) => {
    //         return res.path().split('/').pop();
    //     });
}

export function getNextWorkflowScreen(workflowsJson, actionToLog) {
    let workFlows = [];
    if (!_.isEmpty(workflowsJson)) workFlows = JSON.parse(workflowsJson);
    //check if any forms exist
    // marking the screen undefined, leaves it to the type of activity (in/out)
    workFlows = workFlows.filter((workflow) => {
        return (!_.isEmpty(workflow.screens[0]))
    });
    const filters = actionToLog === "check-in" ?
        {'status': 1} : {'status': 2};
    workFlows = workFlows.filter((cond) => {
        return Object.keys(filters).every((key) => cond[key] === filters[key])
    });
    let screen = workFlows.length === 0 ? undefined :
        workFlows.length === 1 ? 'workflow' : 'workflowType';
    return {
        workFlows,
        screen
    }
}


export function getOs() {
    let OSName = "IOS";
    if (navigator.appVersion.indexOf("Win") !== -1)
        OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") !== -1)
        OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") !== -1)
        OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") !== -1)
        OSName = "Linux";
    if (navigator.appVersion.indexOf("iPhone") !== -1)
        OSName = "IOS";
    if (navigator.appVersion.indexOf("Android") !== -1)
        OSName = "Android";
    return OSName
}
